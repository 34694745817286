import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ListItemNumberModel from "../../models/listItemNumberModel";
import SupervisorForSettlementRestModel from "../../models/rest/supervisor/supervisorForSettlementRestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";

@Component
export default class SupervisorDialog extends Vue {
    supervisorSearch = "";
    supervisor = {} as SupervisorRestModel;

    rules = {
        supervisor: [
            (value: ListItemNumberModel) => !!value || this.$t("common.validationErrorRequired"),
            (value: ListItemNumberModel) => Object.keys(value).length != 0 || this.$t("common.validationErrorRequired")
        ],
    }

    get showDialog(): boolean {
        return this.$store.direct.state.settlementCommon.showAssignSupervisorDialog;
    }

    get title(): string {
        return this.$store.direct.state.settlementCommon.assignSupervisorDialogTitle;
    }

    get supervisorFound(): SupervisorRestModel[] {
        return this.$store.direct.state.supervisor.supervisorSearch;
    }

    set supervisorFound(val: SupervisorRestModel[]) {
        this.$store.direct.commit.supervisor.setSupervisorSearch(val);
    }

    get supervisorList(): SupervisorForSettlementRestModel[] {
        return this.$store.direct.state.settlementCommon.supervisorList;
    }

    set supervisorList(val: SupervisorForSettlementRestModel[]) {
        this.$store.direct.commit.settlementCommon.setSupervisorList(val);
    }

    @Watch("supervisorSearch")
    async supervisorSearchLoadItems(val: string) {
        if (this.supervisorSearch) {
            await this.$store.direct.dispatch.supervisor.searchSupervisorsForSettlement({ searchText: val });
        }
    }

    cancelClick() {
        this.$store.direct.dispatch.settlementCommon.isNotConfirmedAssignSupervisorDialog();
        this.supervisor = {} as SupervisorRestModel;
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.settlementCommon.isConfirmedAssignSupervisorDialog();

            const supervisorExists = this.supervisorList.find((x: SupervisorForSettlementRestModel) => {
                return x.id == this.supervisor.id;
            });
            if (supervisorExists == undefined) {

                const result = await this.$store.direct.dispatch.supervisorForSettlement.checkIfAllSupervisorHaveSaleValueRecalculatedByAccounting({ supervisorIdcs: [this.supervisor.id] });
                if (!result) {
                    return;
                }

                this.supervisorList.push(this.supervisor)
            }
            this.supervisor = {} as SupervisorRestModel;
        }
    }
}
