import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import PolicyDialog from "../../components/contractor/dialog/policy.vue";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import { ContractorTypes } from "../../models/emums/contractorTypes";
import { Roles } from "../../models/emums/roles";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import LocalStorageApplicationStateDataModel from "../../models/localStorageApplicationStateDataModel";
import ContractorAssetsRestModel from "../../models/rest/contractor/contractorAssetsRestModel";
import ContractorDetailsForSupervisorRestModel from "../../models/rest/contractor/contractorDetailsForSupervisorRestModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import CourierNetFreightRestModel from "../../models/rest/freight-exchange/courier-net/courierNetFreightRestModel";
import CourierNetPackageRestModel from "../../models/rest/freight-exchange/courier-net/courierNetPackageRestModel";
import TransEuFreightRestModel from "../../models/rest/freight-exchange/transeu/transEuFreightRestModel";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";
import DepartmentItemRestModel from "../../models/rest/register/departmentItemRestModel";
import SupervisorEncoryHandlingUnitForSupervisorPlaceModel from "../../models/rest/supervisor-encory/supervisorEncoryHandlingUnitForSupervisorPlaceRestModel";
import SupervisorAttachmentRestModel from "../../models/rest/supervisor/supervisorAttachmentRestModel";
import SupervisorCheckpointRestModel from "../../models/rest/supervisor/supervisorCheckpointRestModel";
import SupervisorMementoItemRestModel from "../../models/rest/supervisor/supervisorMementoItemRestModel";
import SupervisorPlaceRestModel from "../../models/rest/supervisor/supervisorPlaceRestModel";
import SupervisorProcedureRestModel from "../../models/rest/supervisor/supervisorProcedureRestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";
import FreightDialog from "../freight-exchange/freight-dialog.vue";
import AttachmentDialog from "./dialog/attachment-dialog.vue";
import ContractorDialog from "./dialog/contractor-dialog.vue";
import ContractorContactPersonDialog from "./dialog/contractor-contact-person-dialog.vue";
import EncoryIntegrationDialog from "./dialog/encory-integration-dialog.vue";
import IntegrationDialog from "./dialog/integration-dialog.vue";
import LocationDialog from "./dialog/location-dialog.vue";
import ProcedureDialog from "./dialog/procedure-dialog.vue";
import SaveSupervisorDialog from "./dialog/save-supervisor-dialog.vue";
import SendingEmailDialog from "./dialog/sending-email-dialog.vue";
import SendingSmsDialog from "./dialog/sending-sms-dialog.vue";
import TransportDocumentDialog from "./dialog/transport-document-dialog.vue";
import ContractorRestModel from "../../models/rest/contractor/contractorRestModel";
import Tiptap from '../custom/tiptap.vue';
import SupervisorProjectFreightCalculationRestModel from "../../models/rest/supervisor/supervisorProjectFreightCalculationRestModel";
import CountryRestModel from "../../models/rest/register/countryRestModel";

export interface contractorCarrierPolicyIsActiveResultInterface {
    policyIsActive: boolean;
    checkedPolicyType?: string;
}

@Component({
    components: {
        "attachment-dialog": AttachmentDialog,
        "confirm-dialog": ConfirmDialog,
        "contractor-dialog": ContractorDialog,
        "contractor-contact-person-dialog": ContractorContactPersonDialog,
        "freight-dialog": FreightDialog,
        "integration-dialog": IntegrationDialog,
        "encory-integration-dialog": EncoryIntegrationDialog,
        "location-dialog": LocationDialog,
        "sending-email-dialog": SendingEmailDialog,
        "sending-sms-dialog": SendingSmsDialog,
        "save-supervisor-dialog": SaveSupervisorDialog,
        "policy-dialog": PolicyDialog,
        "procedure-dialog": ProcedureDialog,
        "transport-document-dialog": TransportDocumentDialog,
        Tiptap,
    }
})

export default class Supervisor extends Vue {
    isEdited = false;
    isFullDuplicating = false;
    currentSupervisorPlaces: SupervisorPlaceRestModel[] = [];
    currentSupervisorCheckpoints: SupervisorCheckpointRestModel[] = [];
    planningDateLabel = this.$t("supervisor.planning");
    planningDateToLabel = this.$t("supervisor.planningTo");
    doneDateLabel = this.$t("supervisor.done");
    kpiReceiptDate = this.$t("supervisor.kpiReceiptDate");
    doneDateConfirmationDialogTitle = this.$t("supervisor.doneDateConfirmationDialogTitle");
    doneDateConfirmationDialogMessage = this.$t("supervisor.doneDateConfirmationDialogMessage");
    contractorSenderSearch = "";
    contractorCarrierSearch = "";
    contractorPayerSearch = "";
    integrationEnabled = false;
    encoryIntegrationEnabled = false;
    kpiEnabled = false;
    showErrorDialog = false;
    timeout: number | undefined;
    canChangeRouteToSupervisorList: boolean = true;
    contractorCarrierPolicyIsActiveResult: contractorCarrierPolicyIsActiveResultInterface = { policyIsActive: false };
    defaultLocationId = (this.supervisor.locationId != undefined && this.supervisor.locationId != 0) ? this.supervisor.locationId : this.currentUserDefaultDepartmentId;
    copyOfDefaultLocationId = this.defaultLocationId;
    previousBranchId: number | undefined = undefined;
    lastSupervisorMementoPoint: number = 0;
    supervisorMementoSelectedPoint: number = 0;
    placesError = null as string | null;
    supervisorIsEditedByAnotherUser = false;
    updateSupervisorLockedForEditingUntilValueIntervalId: any;
    supervisorKilometersHints = "";
    needRecalculatingFreight = false;

    unitTypeArray = [
        "palette", "gitter", "box", "Gasflasche", "Leeres Gefäß", "other"
    ] as string[];

    weightUnitTypeArray = [
        "kg", "t", "L"
    ] as string[];

    yesNoItems = [
        { text: this.$t("common.yes"), value: true },
        { text: this.$t("common.no"), value: false },
    ];

    rules = {
        locationId: [
            () => {
                const error = this.validationError.LocationId != undefined ? this.validationError.LocationId[0] : "";
                return error == "" || error;
            }],
        customerOrderId: [
            () => {
                const error = this.validationError.CustomerOrderId != undefined ? this.validationError.CustomerOrderId[0] : "";
                return error == "" || error;
            }],
        customerCompanyId: [
            () => {
                const error = this.validationError.CustomerCompanyId != undefined ? this.validationError.CustomerCompanyId[0] : "";
                return error == "" || error;
            }],
        customerContact: [
            () => {
                const error = this.validationError.CustomerContact != undefined ? this.validationError.CustomerContact[0] : "";
                return error == "" || error;
            }],
        customerContactPerson: [
            () => {
                const error = this.validationError.CustomerContactPerson != undefined ? this.validationError.CustomerContactPerson[0] : "";
                return error == "" || error;
            }],
        payerId: [
            () => {
                const error = this.validationError.PayerId != undefined ? this.validationError.PayerId[0] : "";
                return error == "" || error;
            }],
        driver1Name: [
            () => {
                const error = this.validationError.Driver1Name != undefined ? this.validationError.Driver1Name[0] : "";
                return error == "" || error;
            }],
        driver1Telephone: [
            () => {
                const error = this.validationError.Driver1Telephone != undefined ? this.validationError.Driver1Telephone[0] : "";
                return error == "" || error;
            }],
        orderId: [
            () => {
                const error = this.validationError.OrderId != undefined ? this.validationError.OrderId[0] : "";
                return error == "" || error;
            }],
        shippingCompanyId: [
            () => {
                const error = this.validationError.ShippingCompanyId != undefined ? this.validationError.ShippingCompanyId[0] : "";
                return error == "" || error;
            }
        ],
        registrationNumber: [
            () => {
                const error = this.validationError.RegistrationNumber != undefined ? this.validationError.RegistrationNumber[0] : "";
                return error == "" || error;
            }],
        saleValue: [
            () => {
                const error = this.validationError.SaleValue != undefined ? this.validationError.SaleValue[0] : "";
                return error == "" || error;
            }],
        saleValueCurrency: [
            () => {
                const error = this.validationError.SaleValueCurrency != undefined ? this.validationError.SaleValueCurrency[0] : "";
                return error == "" || error;
            }],
        paymentTermSale: [
            () => {
                const error = this.validationError.PaymentTermSale != undefined ? this.validationError.PaymentTermSale[0] : "";
                return error == "" || error;
            }],
        purchaseValue: [
            () => {
                const error = this.validationError.PurchaseValue != undefined ? this.validationError.PurchaseValue[0] : "";
                return error == "" || error;
            }],
        purchaseValueCurrency: [
            () => {
                const error = this.validationError.PurchaseValueCurrency != undefined ? this.validationError.PurchaseValueCurrency[0] : "";
                return error == "" || error;
            }],
        paymentTermPurchase: [
            () => {
                const error = this.validationError.PaymentTermPurchase != undefined ? this.validationError.PaymentTermPurchase[0] : "";
                return error == "" || error;
            }],
        placeName: [
            () => {
                const error = this.validationError.Name != undefined ? this.validationError.Name[0] : "";
                return error == "" || error;
            }],
        placeCountry: [
            () => {
                const error = this.validationError.Country != undefined ? this.validationError.Country[0] : "";
                return error == "" || error;
            }],
        placePostalCode: [
            () => {
                const error = (this.validationError.IsLocationConfirmedByUser != undefined) ? this.validationError.IsLocationConfirmedByUser[0] : "";
                return error == "" || error;
            },
            () => {
                const error = this.validationError.PostalCode != undefined ? this.validationError.PostalCode[0] : "";
                return error == "" || error;
            }],
        placeCity: [
            () => {
                const error = this.validationError.City != undefined ? this.validationError.City[0] : "";
                return error == "" || error;
            }
        ],
        placeWeight: [
            () => {
                const error = this.validationError.Weight != undefined ? this.validationError.Weight[0] : "";
                return error == "" || error;
            }],
        placeWeightUnit: [
            () => {
                const error = this.validationError.WeightUnit != undefined ? this.validationError.WeightUnit[0] : "";
                return error == "" || error;
            }],
        placeCount: [
            () => {
                const error = this.validationError.Count != undefined ? this.validationError.Count[0] : "";
                return error == "" || error;
            }],
        placeUnit: [
            () => {
                const error = this.validationError.Unit != undefined ? this.validationError.Unit[0] : "";
                return error == "" || error;
            }],
        planningDate: [
            () => {
                const error = this.validationError.Time != undefined ? this.validationError.Time[0] : "";
                return error == "" || error;
            }],
        whoPaysForCustomClearanceTypeId: [
            () => {
                const error = this.validationError.WhoPaysForCustomClearanceTypeId != undefined ? this.validationError.WhoPaysForCustomClearanceTypeId[0] : "";
                return error == "" || error;
            }],
        checkpointDescription: [
            () => {
                const error = this.validationError.Description != undefined ? this.validationError.Description[0] : "";
                return error == "" || error;
            }],
        checkpointCheckTime: [
            () => {
                const error = this.validationError.CheckTime != undefined ? this.validationError.CheckTime[0] : "";
                return error == "" || error;
            }],
        additionalDeclarationsComments: [
            () => {
                const error = this.validationError.AdditionalDeclarationsComments != undefined ? this.validationError.AdditionalDeclarationsComments[0] : "";
                return error == "" || error;
            }],
        numberOfKilometers: [
            () => {
                const error = this.validationError.NumberOfKilometers != undefined ? this.validationError.NumberOfKilometers[0] : "";
                return error == "" || error;
            }],
        vehicleType: [
            () => {
                const error = this.validationError.VehicleType != undefined ? this.validationError.VehicleType[0] : "";
                return error == "" || error;
            }],
        kpiLifts: [
            () => {
                const error = this.validationError.KpiLifts != undefined ? this.validationError.KpiLifts[0] : "";
                return error == "" || error;
            }],
        places: [
            () => {
                const error = this.validationError.Places != undefined ? this.validationError.Places[0] : "";
                return error == "" || error;
            }],
    };

    get isSupervisorInHistoricalState(): boolean {
        return this.$store.direct.state.supervisor.isSupervisorInHistoricalState;
    }

    set isSupervisorInHistoricalState(val: boolean) {
        this.$store.direct.commit.supervisor.setIsSupervisorInHistoricalState(val);
    }

    get attachmentList(): SupervisorAttachmentRestModel[] {
        return this.$store.direct.state.supervisor.attachmentList;
    }

    set attachmentList(value: SupervisorAttachmentRestModel[]) {
        this.$store.direct.commit.supervisor.setAttachmentList(value);
    }

    get procedureList(): SupervisorProcedureRestModel[] {
        return this.$store.direct.state.supervisor.procedureList;
    }

    set procedureList(value: SupervisorProcedureRestModel[]) {
        this.$store.direct.commit.supervisor.setProcedureList(value);
    }

    get vehicleTypes(): ListItemStringModel[] {
        return this.$store.direct.state.common.vehicleTypeListForSupervisor;
    }

    get occurredErrorWhileSaving(): boolean {
        return this.$store.direct.state.supervisor.occurredErrorWhileSaving;
    }

    set occurredErrorWhileSaving(value: boolean) {
        this.$store.direct.commit.supervisor.setOccurredErrorWhileSaving(value);
    }

    get occurredErrorWhileSavingMessage(): string {
        return this.$store.direct.state.supervisor.occurredErrorWhileSavingMessage;
    }

    set occurredErrorWhileSavingMessage(value: string) {
        this.$store.direct.commit.supervisor.setOccurredErrorWhileSavingMessage(value);
    }

    get supervisorEditedByAnotherUser(): boolean {
        return this.$store.direct.state.supervisor.supervisorEditedByAnotherUser;
    }

    set supervisorEditedByAnotherUser(value: boolean) {
        this.$store.direct.commit.supervisor.setSupervisorEditedByAnotherUser(value);
    }

    get orderType(): ListItemNumberModel[] {
        return this.$store.direct.state.supervisor.supervisorPlaceOrderType;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get currentUserDepartments(): number[] {
        const currentUserDepartments = this.$store.direct.state.auth.currentUserDepartments;
        if (!currentUserDepartments.includes(this.currentUserDefaultDepartmentId)) {
            currentUserDepartments.push(this.currentUserDefaultDepartmentId);
        }
        return currentUserDepartments;
    }

    get departments(): ListItemNumberModel[] {
        const departments = this.$store.direct.state.contractor.contractorAssets.departments;
        if (departments != undefined) {
            if ((this.isEdited == false || this.userIsAdminOrUserCreatedOrder) || this.hangfireUserEmail == this.supervisor.createdByUserEmail) {
                const allowedDepartments = departments.filter((x: ListItemNumberModel) => {
                    if (this.currentUserDepartments.includes(x.value)) {
                        return x;
                    }
                });
                return allowedDepartments;
            }
            else {
                return departments;
            }
        }
        return [] as ListItemNumberModel[];
    }

    get currentUserDefaultDepartmentId(): number {
        return this.$store.direct.state.auth.currentUserDefaultDepartmentId;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get hangfireUserEmail(): string {
        return this.$store.direct.state.common.hangfireUserEmail;
    }

    get allowEditContractorSenderFreightPaymentTermAndCurrency() {
        if (this.currentUserRoles.includes(Roles.Admin) || typeof (this.selectedContractorSender) == "string") {
            return true;
        }
        else if ((this.currentUserRoles.includes(Roles.DisponentAdmin) || this.currentUserRoles.includes(Roles.Accountant) || this.currentUserRoles.includes(Roles.AccountantAdmin)) && (this.supervisor.locationId == null || this.currentUserDepartments.includes(this.supervisor.locationId))) {
            return true;
        }
        return false;
    }

    get supervisor(): SupervisorRestModel {
        const supervisor = this.$store.direct.state.supervisor.supervisor as SupervisorRestModel;

        if (supervisor.isEditedByUserName != null) {
            this.supervisorIsEditedByAnotherUser = true;
            this.showSupervisorIsEditedByAnotherUserDialog(supervisor.isEditedByUserName);
        }

        if (supervisor.checkpoints != undefined) {
            supervisor.checkpoints = this.formatDateTime(supervisor.checkpoints, "checkTime");
        }
        if (supervisor.places != undefined) {
            supervisor.places = this.formatDateTime(supervisor.places, "time");
            supervisor.places = this.formatDateTime(supervisor.places, "timeTo");
            supervisor.places = this.formatDateTime(supervisor.places, "realTime");
        }
        if (this.currentSupervisorCheckpoints.length == 0) {
            this.currentSupervisorCheckpoints = supervisor.checkpoints != undefined ? supervisor.checkpoints : [];
        }
        if (this.currentSupervisorPlaces.length == 0) {
            this.currentSupervisorPlaces = supervisor.places != undefined ? supervisor.places : [];
        }

        if (this.previousBranchId == undefined) {
            this.previousBranchId = this.contractorAssets.departments?.find((x: DepartmentItemRestModel) => { return x.value == this.defaultLocationId; })?.branchId
        }

        if (this.isSupervisorInHistoricalState) {
            this.defaultLocationId = supervisor.locationId;
        }

        if (supervisor.customerContactPerson != null && supervisor.customerContactPerson.value == 0 && this.contractorSenderDetailsForSupervisor.contactPersonList != undefined) {
            this.contractorSenderDetailsForSupervisor.contactPersonList.push({ text: supervisor.customerContactPerson.text, value: supervisor.customerContactPerson.value } as ListItemNumberModel)
        }

        return supervisor;
    }

    get currentUserEmail(): string {
        return this.$store.direct.state.auth.currentUserEmail;
    }

    get userIsAdminOrUserCreatedOrder() {
        if (this.currentUserRoles.includes(Roles.Admin) || this.supervisor.createdByUserEmail == this.currentUserEmail) {
            return true;
        }
        return false;
    }

    get userIsAdminDisponentAdminOrDisponent(): boolean {
        return (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.DisponentAdmin) || this.currentUserRoles.includes(Roles.Disponent)) ? true : false;
    }

    get showFreightCalculationForAccountingField(): boolean {
        return this.currentUserRoles.includes(Roles.AccountantAdmin) || this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.Accountant);
    }

    get supervisorPlace(): SupervisorPlaceRestModel {
        return this.$store.direct.state.supervisor.supervisorPlace;
    }

    get validationError(): any {
        return this.$store.direct.state.supervisor.validationError;
    }

    get contractorSenderDetailsForSupervisor(): ContractorDetailsForSupervisorRestModel {
        let contractorSenderDetailsForSupervisor = this.$store.direct.state.contractor.contractorSenderDetailsForSupervisor as ContractorDetailsForSupervisorRestModel;

        if (Object.keys(contractorSenderDetailsForSupervisor).length == 0) {
            contractorSenderDetailsForSupervisor = { id: 0, name: "", integrationEnabled: false, encoryIntegrationEnabled: false, kpiEnabled: false, departmentIdcs: [] as number[] } as ContractorDetailsForSupervisorRestModel;
        }
        else {
            this.integrationEnabled = contractorSenderDetailsForSupervisor.integrationEnabled;
            this.encoryIntegrationEnabled = contractorSenderDetailsForSupervisor.encoryIntegrationEnabled;
            this.kpiEnabled = contractorSenderDetailsForSupervisor.kpiEnabled;
        }

        if (contractorSenderDetailsForSupervisor.isLockedAsClient == true) {
            this.showContractorIsLockedAsClientDialog(contractorSenderDetailsForSupervisor)
            contractorSenderDetailsForSupervisor = { id: 0, name: "", integrationEnabled: false, encoryIntegrationEnabled: false, kpiEnabled: false, departmentIdcs: [] as number[] } as ContractorDetailsForSupervisorRestModel;
            this.contractorSenderSearch = "";
            this.selectedContractorSender = "" as any;
        }

        return contractorSenderDetailsForSupervisor;
    }

    set contractorSenderDetailsForSupervisor(val: ContractorDetailsForSupervisorRestModel) {
        this.$store.direct.commit.contractor.setContractorSenderDetailsForSupervisor(val);
    }

    get contractorCarrierDetailsForSupervisor(): ContractorDetailsForSupervisorRestModel {
        let contractorCarrierDetailsForSupervisor = this.$store.direct.state.contractor.contractorCarrierDetailsForSupervisor as ContractorDetailsForSupervisorRestModel;

        if (Object.keys(contractorCarrierDetailsForSupervisor).length == 0) {
            contractorCarrierDetailsForSupervisor = { id: 0, name: "", integrationEnabled: false, encoryIntegrationEnabled: false, kpiEnabled: false, departmentIdcs: [] as number[] } as ContractorDetailsForSupervisorRestModel;
        }

        if (contractorCarrierDetailsForSupervisor.isLockedAsCarrier == true) {
            this.showContractorIsLockedAsCarrierDialog(contractorCarrierDetailsForSupervisor)
            contractorCarrierDetailsForSupervisor = { id: 0, name: "", integrationEnabled: false, encoryIntegrationEnabled: false, kpiEnabled: false, departmentIdcs: [] as number[] } as ContractorDetailsForSupervisorRestModel;
            this.contractorCarrierSearch = ""
            this.selectedContractorCarrier = "" as any;
        }

        return contractorCarrierDetailsForSupervisor;
    }

    set contractorCarrierDetailsForSupervisor(val: ContractorDetailsForSupervisorRestModel) {
        this.$store.direct.commit.contractor.setContractorCarrierDetailsForSupervisor(val);
    }

    get contractorPayerDetailsForSupervisor(): ContractorDetailsForSupervisorRestModel {
        let contractorPayerDetailsForSupervisor = this.$store.direct.state.contractor.contractorPayerDetailsForSupervisor as ContractorDetailsForSupervisorRestModel;

        if (Object.keys(contractorPayerDetailsForSupervisor).length == 0) {
            contractorPayerDetailsForSupervisor = { id: 0, name: "", integrationEnabled: false, encoryIntegrationEnabled: false, kpiEnabled: false, departmentIdcs: [] as number[] } as ContractorDetailsForSupervisorRestModel;
        }

        if (contractorPayerDetailsForSupervisor.isLockedAsClient == true) {
            this.showContractorIsLockedAsClientDialog(contractorPayerDetailsForSupervisor)
            contractorPayerDetailsForSupervisor = { id: 0, name: "", integrationEnabled: false, encoryIntegrationEnabled: false, kpiEnabled: false, departmentIdcs: [] as number[] } as ContractorDetailsForSupervisorRestModel;
            this.contractorPayerSearch = ""
            this.selectedContractorPayer = "" as any;
        }

        return contractorPayerDetailsForSupervisor;
    }

    set contractorPayerDetailsForSupervisor(val: ContractorDetailsForSupervisorRestModel) {
        this.$store.direct.commit.contractor.setContractorPayerDetailsForSupervisor(val);
    }

    get userCanSeeContractorSenderAndHisFreightDetails(): boolean {
        if (this.contractorSenderDetailsForSupervisor.disableField == true) {
            return false;
        };

        if (this.currentUserRoles.includes(Roles.Admin)
            || this.supervisor.locationId == null
            || this.currentUserDepartments.includes(this.supervisor.locationId)) {
            return true;
        }

        return false;
    }

    get userCanSeeContractorCarrierAndHisFreightDetails(): boolean {
        if (this.contractorCarrierDetailsForSupervisor.disableField == true) {
            return false;
        };

        if (this.currentUserRoles.includes(Roles.Admin)
            || this.supervisor.locationId == null
            || this.currentUserDepartments.includes(this.supervisor.locationId)) {
            return true;
        }

        return false;
    }

    get selectedContractorSender(): ContractorSearchItemModel {
        const selectedContractorSender = this.$store.direct.state.supervisor.selectedContractorSender;
        if (selectedContractorSender != null && selectedContractorSender.text != undefined && selectedContractorSender.text && selectedContractorSender.value == 0) {
            this.contractorSenderFound.push({ text: selectedContractorSender.text, value: 0, additionalInfo: "" } as ContractorSearchItemModel);
        }
        if (selectedContractorSender != null && selectedContractorSender.value != undefined && selectedContractorSender.value != 0) {
            this.contractorSenderFound.push({ text: selectedContractorSender.text, value: selectedContractorSender.value, additionalInfo: selectedContractorSender.additionalInfo } as ContractorSearchItemModel);
            this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: selectedContractorSender.value, contractorType: ContractorTypes.Client })
        }
        if (selectedContractorSender != null && selectedContractorSender.value == undefined) {
            this.integrationEnabled = false;
            this.encoryIntegrationEnabled = false;
            this.kpiEnabled = false;
        }
        return selectedContractorSender;
    }

    set selectedContractorSender(val: ContractorSearchItemModel) {
        this.$store.direct.commit.supervisor.setSelectedContractorSender(val);
    }

    get selectedContractorCarrier(): ContractorSearchItemModel {
        const selectedContractorCarrier = this.$store.direct.state.supervisor.selectedContractorCarrier;
        if (selectedContractorCarrier != null && selectedContractorCarrier.text != undefined && selectedContractorCarrier.text && selectedContractorCarrier.value == 0) {
            this.contractorCarrierFound.push({ text: selectedContractorCarrier.text, value: 0, additionalInfo: "" } as ContractorSearchItemModel);
        }
        if (selectedContractorCarrier != null && selectedContractorCarrier.value != undefined && selectedContractorCarrier.value != 0) {
            this.contractorCarrierFound.push({ text: selectedContractorCarrier.text, value: selectedContractorCarrier.value, additionalInfo: selectedContractorCarrier.additionalInfo } as ContractorSearchItemModel);
            this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: selectedContractorCarrier.value, contractorType: ContractorTypes.Carrier })
        }
        return selectedContractorCarrier;
    }

    set selectedContractorCarrier(val: ContractorSearchItemModel) {
        this.$store.direct.commit.supervisor.setSelectedContractorCarrier(val);
    }

    get selectedContractorPayer(): ContractorSearchItemModel {
        const selectedContractorPayer = this.$store.direct.state.supervisor.selectedContractorPayer;
        if (selectedContractorPayer != null && selectedContractorPayer.text != undefined && selectedContractorPayer.text && selectedContractorPayer.value == 0) {
            this.contractorPayerFound.push({ text: selectedContractorPayer.text, value: 0, additionalInfo: "" } as ContractorSearchItemModel);
        }
        if (selectedContractorPayer != null && selectedContractorPayer.value != undefined && selectedContractorPayer.value != 0) {
            this.contractorPayerFound.push({ text: selectedContractorPayer.text, value: selectedContractorPayer.value, additionalInfo: selectedContractorPayer.additionalInfo } as ContractorSearchItemModel);
            this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: selectedContractorPayer.value, contractorType: ContractorTypes.Payer })
        }
        if (selectedContractorPayer != null && selectedContractorPayer.value == undefined) {
            this.integrationEnabled = false;
            this.integrationEnabled = false;
            this.kpiEnabled = false;
        }
        return selectedContractorPayer;
    }

    set selectedContractorPayer(val: ContractorSearchItemModel) {
        this.$store.direct.commit.supervisor.setSelectedContractorPayer(val);
    }

    get contractorSenderFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorSenderFound;
    }

    get contractorCarrierFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorCarrierFound;
    }

    get contractorPayerFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorPayerFound;
    }

    get showAddContractorCarrierButton(): boolean {
        if (this.contractorCarrierFound.length == 0
            && this.contractorCarrierSearch
            && this.userCanSeeContractorCarrierAndHisFreightDetails) {
            return true;
        }
        return false;
    }

    get showEditContractorCarrierButton(): boolean {
        if (this.contractorCarrierFound.length > 0
            && this.selectedContractorCarrier != null
            && this.selectedContractorCarrier.value != undefined
            && this.selectedContractorCarrier.value != 0
            && this.userCanSeeContractorCarrierAndHisFreightDetails) {
            return true;
        }
        return false;
    }

    get showAddContractorSenderButton(): boolean {
        if (this.contractorSenderFound.length == 0
            && this.contractorSenderSearch
            && this.userCanSeeContractorSenderAndHisFreightDetails
            && (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.DisponentAdmin))) {
            return true;
        }
        return false;
    }

    get showEditContractorSenderButton(): boolean {
        if (this.contractorSenderFound.length > 0
            && this.userCanSeeContractorSenderAndHisFreightDetails
            && this.selectedContractorSender != null
            && this.selectedContractorSender.value != undefined
            && this.selectedContractorSender.value != 0
            && (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.DisponentAdmin))) {
            return true;
        }
        return false;
    }

    get placeNamesFound(): SupervisorPlaceRestModel[] {
        return this.$store.direct.state.supervisor.supervisorPlaceNamesFound;
    }

    set placeNamesFound(val: SupervisorPlaceRestModel[]) {
        this.$store.direct.commit.supervisor.setSupervisorPlaceNamesFound(val);
    }

    get placeCitiesFound(): SupervisorPlaceRestModel[] {
        return this.$store.direct.state.supervisor.supervisorPlaceCitiesFound;
    }

    get placePostalCodesFound(): SupervisorPlaceRestModel[] {
        return this.$store.direct.state.supervisor.supervisorPlacePostalCodesFound;
    }

    get checkIfContractorPolicyIsActive(): boolean {
        return this.$store.direct.state.contractor.checkIfContractorPolicyIsActive;
    }

    get checkIfContractorClientHasExceededDebtLimit(): boolean {
        return this.$store.direct.state.contractor.checkIfContractorClientHasExceededDebtLimit;
    }

    get checkIfContractorPayerHasExceededDebtLimit(): boolean {
        return this.$store.direct.state.contractor.checkIfContractorPayerHasExceededDebtLimit;
    }

    get isDroppedFromPreliminaryList(): boolean {
        return this.$store.direct.state.supervisor.isDroppedFromPreliminaryList;
    }

    get supervisorMementoListCount(): number {
        const supervisorMementoListCount = this.$store.direct.state.supervisor.supervisorMementoListCount - 1;
        this.lastSupervisorMementoPoint = supervisorMementoListCount;
        this.supervisorMementoSelectedPoint = supervisorMementoListCount;
        return supervisorMementoListCount;
    }

    get supervisorMementoList(): SupervisorMementoItemRestModel[] {
        return this.$store.direct.state.supervisor.supervisorMementoList;
    }

    get disableSupervisorMementoSwitch(): boolean {
        return this.supervisorMementoListCount < 1 ? true : false;
    }

    get addressFound() {
        if (this.$store.direct.state.common.addressFound == null) {
            return [];
        }
        return this.$store.direct.state.common.addressFound;
    }

    get currencies(): CurrencyRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.currencies;
    }

    get backToSupervisorArchiveListForSettlement(): boolean {
        return this.$store.direct.state.supervisorForSettlement.backToSupervisorArchiveListForSettlement;
    }

    set backToSupervisorArchiveListForSettlement(val: boolean) {
        this.$store.direct.commit.supervisorForSettlement.setBackToSupervisorArchiveListForSettlement(val);
    }

    get backToSupervisorAllOrdersList(): boolean {
        return this.$store.direct.state.supervisorAllOrders.backToSupervisorAllOrdersList;
    }

    set backToSupervisorAllOrdersList(val: boolean) {
        this.$store.direct.commit.supervisorAllOrders.setBackToSupervisorAllOrdersList(val);
    }

    get isSupervisorCopied(): boolean {
        return this.$store.direct.state.supervisor.isSupervisorCopied;
    }

    set isSupervisorCopied(val: boolean) {
        this.$store.direct.commit.supervisor.setIsSupervisorCopied(val);
    }

    get supervisorBaseToCopy(): SupervisorRestModel {
        return this.$store.direct.state.supervisor.supervisorBaseToCopy;
    }

    set supervisorBaseToCopy(val: SupervisorRestModel) {
        this.$store.direct.commit.supervisor.setSupervisorBaseToCopy(val);
    }

    get disableSaveSupervisorDueToPossessionOfCostOrIncomeDocuments(): boolean {
        const result = (!this.currentUserRoles.includes(Roles.Admin) && !this.currentUserRoles.includes(Roles.AccountantAdmin) && !this.currentUserRoles.includes(Roles.Accountant)) && this.supervisor.hasCostOrIncomeDocumentsAssigned;
        return result;
    }

    get enableSaveSupervisorOnlyForAdminAndAccountantAdminIfSupervisorsHasAnyCostOrIncomeDocumetWhichHasCorrection(): boolean {
        const result = (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin)) || !this.supervisor.hasAnyCostOrIncomeDocumentsWhichHasCorrection;
        return result;
    }

    get contractorAssets(): ContractorAssetsRestModel {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get localStorageApplicationStateData(): LocalStorageApplicationStateDataModel {
        return this.$store.direct.state.common.localStorageApplicationStateData;
    }

    get isSupervisorCompletedOrder(): boolean {
        return !this.supervisor.isPreliminary;
    }

    set isSupervisorCompletedOrder(val: boolean) {
        this.supervisor.isPreliminary = !val;
    }

    get registrationNumberLabel() {
        this.supervisorBaseToCopy.registrationNumber = this.supervisorBaseToCopy.registrationNumber == null ? "" : this.supervisorBaseToCopy.registrationNumber;
        return this.isFullDuplicating ? `${this.$t("supervisor.registrationNumber")}` + ' - ' + this.supervisorBaseToCopy.registrationNumber : `${this.$t("supervisor.registrationNumber")}`
    }

    get driver1NameLabel() {
        this.supervisorBaseToCopy.driver1Name = this.supervisorBaseToCopy.driver1Name == null ? "" : this.supervisorBaseToCopy.driver1Name;
        return this.isFullDuplicating ? `${this.$t("supervisor.driverName")}` + ' - ' + this.supervisorBaseToCopy.driver1Name : `${this.$t("supervisor.driverName")}`
    }

    get driver1TelephoneLabel() {
        this.supervisorBaseToCopy.driver1Telephone = this.supervisorBaseToCopy.driver1Telephone == null ? "" : this.supervisorBaseToCopy.driver1Telephone;
        return this.isFullDuplicating ? `${this.$t("supervisor.driverTelephone")}` + ' - ' + this.supervisorBaseToCopy.driver1Telephone : `${this.$t("supervisor.driverTelephone")}`
    }

    get driver2NameLabel() {
        this.supervisorBaseToCopy.driver2Name = this.supervisorBaseToCopy.driver2Name == null ? "" : this.supervisorBaseToCopy.driver2Name;
        return this.isFullDuplicating ? `${this.$t("supervisor.driverName2")}` + ' - ' + this.supervisorBaseToCopy.driver2Name : `${this.$t("supervisor.driverName2")}`
    }

    get driver2TelephoneLabel() {
        this.supervisorBaseToCopy.driver2Telephone = this.supervisorBaseToCopy.driver2Telephone == null ? "" : this.supervisorBaseToCopy.driver2Telephone;
        return this.isFullDuplicating ? `${this.$t("supervisor.driverTelephone2")}` + ' - ' + this.supervisorBaseToCopy.driver2Telephone : `${this.$t("supervisor.driverTelephone2")}`
    }

    get transEuFreight(): TransEuFreightRestModel {
        return this.$store.direct.state.freightExchange.transEuFreight;
    }

    set transEuFreight(val: TransEuFreightRestModel) {
        this.$store.direct.commit.freightExchange.setTransEuFreight(val);
    }

    get courierNetFreight(): CourierNetFreightRestModel {
        return this.$store.direct.state.freightExchange.courierNetFreight;
    }

    set courierNetFreight(val: CourierNetFreightRestModel) {
        this.$store.direct.commit.freightExchange.setCourierNetFreight(val);
    }

    get currentSupervisorLoadingPlaceId(): undefined | number {
        return this.$store.direct.state.supervisorTransportDocument.currentSupervisorLoadingPlaceId;
    }

    set currentSupervisorLoadingPlaceId(val: undefined | number) {
        this.$store.direct.commit.supervisorTransportDocument.setCurrentSupervisorLoadingPlaceId(val);
    }

    get currentSupervisorUnloadingPlaceId(): undefined | number {
        return this.$store.direct.state.supervisorTransportDocument.currentSupervisorUnloadingPlaceId;
    }

    set currentSupervisorUnloadingPlaceId(val: undefined | number) {
        this.$store.direct.commit.supervisorTransportDocument.setCurrentSupervisorUnloadingPlaceId(val);
    }

    get selectedPlacesIdcsForCreatingNewSupervisor(): (number | undefined)[] {
        return this.currentSupervisorPlaces.filter((x: SupervisorPlaceRestModel) => x.isCheckedForCreatingNewSupervisor == true).map(x => x.id);
    }

    get contractorTags(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.contractorAssets.contractorTags
    }

    get selectedContractorCarrierTagListForFilter(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.selectedContractorCarrierTagListForFilter;
    }

    set selectedContractorCarrierTagListForFilter(val: ListItemNumberModel[]) {
        this.$store.direct.commit.contractor.setSelectedContractorCarrierTagListForFilter(val);
    }

    get sendingSmsDialogPhoneNumber(): string {
        return this.$store.direct.state.supervisor.sendingSmsDialogPhoneNumber;
    }

    set sendingSmsDialogPhoneNumber(val: string) {
        this.$store.direct.commit.supervisor.setSendingSmsDialogPhoneNumber(val);
    }

    get contractorCarrierEmailList(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.emailAddressList;
    }

    set contractorCarrierEmailList(val: ListItemNumberModel[]) {
        this.$store.direct.commit.contractor.setEmailAddressList(val);
    }

    get supervisorProjectFerryChargesCountries(): CountryRestModel[] {
        return this.$store.direct.state.supervisor.supervisorAssets.supervisorProjectFerryChargesCountries;
    }

    @Watch("contractorSenderSearch")
    contractorSenderSearchLoadItems(val: string) {
        this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Client], contractorPropertiesEnabled: [], source: "supervisor", searchOnlyActive: true });
    }

    @Watch("contractorCarrierSearch")
    contractorCarrierSearchLoadItems(val: string) {
        this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Carrier], contractorPropertiesEnabled: [], source: "supervisor", searchOnlyActive: true });
    }

    @Watch("contractorPayerSearch")
    contractorPayerSearchLoadItems(val: string) {
        this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Payer], contractorPropertiesEnabled: [], source: "supervisor", searchOnlyActive: true });
    }

    @Watch("validationError.Places")
    async validationErrorPlacesHandler(val: any) {
        if (this.validationError.Places == undefined) {
            this.placesError = null;
        }
        else {
            this.placesError = this.validationError.Places[0];
        }
    }

    @Watch("supervisor.isPayerRequired")
    async supervisorIsPayerRequiredHandler(val: any) {
        if (this.supervisor.isPayerRequired == false) {
            this.selectedContractorPayer = { text: "", value: 0, additionalInfo: "" };
        }
    }

    @Watch("localStorageApplicationStateData", { deep: true })
    localStorageApplicationStateDataHandles() {
        this.$store.direct.dispatch.common.updateLocalStorageApplicationStateData();
        if (!this.localStorageApplicationStateData.supervisorEditingIsPlaceSuggestionsOn) {
            this.placeNamesFound = [] as SupervisorPlaceRestModel[]
        }
    }

    getSupervisorMementoItem(value: number): SupervisorMementoItemRestModel {
        if (value > -1 && this.supervisorMementoList.length > 0) {
            return this.supervisorMementoList[value];
        }
        return {} as SupervisorMementoItemRestModel;
    }

    setSupervisorPlacesChackpointCurrentPlacesCurrentCheckpointAsEmpty() {
        this.supervisor.checkpoints = [] as SupervisorCheckpointRestModel[];
        this.supervisor.places = [] as SupervisorPlaceRestModel[];
        this.currentSupervisorCheckpoints = [] as SupervisorCheckpointRestModel[];
        this.currentSupervisorPlaces = [] as SupervisorPlaceRestModel[];
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly(evt);
    }

    async supervisorMementoPointChanged(historyPoint: number) {
        if (historyPoint != this.lastSupervisorMementoPoint) {
            this.setSupervisorPlacesChackpointCurrentPlacesCurrentCheckpointAsEmpty();

            if (historyPoint >= 0) {
                this.isSupervisorInHistoricalState = true;
                await this.$store.direct.dispatch.supervisor.getSupervisorMementoPoint({ mementoPoint: historyPoint });
                await this.$store.direct.dispatch.supervisor.loadSupervisorAttachmentList({ mementoPoint: historyPoint });
            }
            this.lastSupervisorMementoPoint = historyPoint;
        }
    }

    async selectedContractorSenderChanged() {
        if (typeof (this.selectedContractorSender) != "string" && this.selectedContractorSender != null) {
            await this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: this.selectedContractorSender.value, contractorType: ContractorTypes.Client })

            if (this.contractorSenderDetailsForSupervisor.specialInfo != null && this.contractorSenderDetailsForSupervisor.specialInfo != undefined) {
                this.$store.direct.dispatch.confirm
                    .openConfirmDialog({
                        showConfirmDialog: true,
                        title: `${this.$t("contractor.specialInfo")}`,
                        message: this.contractorSenderDetailsForSupervisor.specialInfo,
                        links: [],
                        options: { buttonColor: "primary", layoutColor: "primary" },
                        buttonType: "ok"
                    })
                    .then(async confirm => {
                        if (confirm) {
                            this.occurredErrorWhileSaving = false;
                        }
                    });
            }

            if (this.contractorSenderDetailsForSupervisor.specialInfo != null && this.contractorSenderDetailsForSupervisor.specialInfo != undefined) {
                this.$store.direct.dispatch.confirm
                    .openConfirmDialog({
                        showConfirmDialog: true,
                        title: `${this.$t("contractor.specialInfo")}`,
                        message: this.contractorSenderDetailsForSupervisor.specialInfo,
                        links: [],
                        options: { buttonColor: "primary", layoutColor: "primary" },
                        buttonType: "ok"
                    })
                    .then(async confirm => {
                        if (confirm) {
                            this.occurredErrorWhileSaving = false;
                        }
                    });
            }

            if (!this.supervisor.isPayerRequired) {
                this.supervisor.saleValueCurrency = this.contractorSenderDetailsForSupervisor.saleValueCurrency;
                this.supervisor.paymentTermSale = this.contractorSenderDetailsForSupervisor.paymentTermSale;
            }
        }
    }

    async selectedContractorCarrierChanged() {
        if (typeof (this.selectedContractorCarrier) != "string" && this.selectedContractorCarrier != null) {
            await this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: this.selectedContractorCarrier.value, contractorType: ContractorTypes.Carrier })

            this.supervisor.shippingCompanyTags = this.contractorCarrierDetailsForSupervisor.tagsForSupervisor;

            if (this.contractorCarrierDetailsForSupervisor.specialInfo != null && this.contractorCarrierDetailsForSupervisor.specialInfo != undefined) {
                this.$store.direct.dispatch.confirm
                    .openConfirmDialog({
                        showConfirmDialog: true,
                        title: `${this.$t("contractor.specialInfo")}`,
                        message: this.contractorCarrierDetailsForSupervisor.specialInfo,
                        links: [],
                        options: { buttonColor: "primary", layoutColor: "primary" },
                        buttonType: "ok"
                    })
                    .then(async confirm => {
                        if (confirm) {
                            this.occurredErrorWhileSaving = false;
                        }
                    });
            }

            this.supervisor.purchaseValueCurrency = this.contractorCarrierDetailsForSupervisor.purchaseValueCurrency;
            this.supervisor.paymentTermPurchase = this.contractorCarrierDetailsForSupervisor.paymentTermPurchase;
        }
    }

    async selectedContractorPayerChanged() {
        if (typeof (this.selectedContractorPayer) != "string" && this.selectedContractorPayer != null) {
            await this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: this.selectedContractorPayer.value, contractorType: ContractorTypes.Payer })

            if (this.contractorPayerDetailsForSupervisor.specialInfo != null && this.contractorPayerDetailsForSupervisor.specialInfo != undefined) {
                this.$store.direct.dispatch.confirm
                    .openConfirmDialog({
                        showConfirmDialog: true,
                        title: `${this.$t("contractor.specialInfo")}`,
                        message: this.contractorPayerDetailsForSupervisor.specialInfo,
                        links: [],
                        options: { buttonColor: "primary", layoutColor: "primary" },
                        buttonType: "ok"
                    })
                    .then(async confirm => {
                        if (confirm) {
                            this.occurredErrorWhileSaving = false;
                        }
                    });
            }

            this.supervisor.saleValueCurrency = this.contractorPayerDetailsForSupervisor.saleValueCurrency;
            this.supervisor.paymentTermSale = this.contractorPayerDetailsForSupervisor.paymentTermSale;
        }
    }

    @Watch("currentSupervisorPlaces", { deep: true })
    currentSupervisorPlacesHandler() {
        if (this.isEdited == false && this.currentSupervisorPlaces.length >= 2) {
            const firstPlace = this.currentSupervisorPlaces[0];
            const secondPlace = this.currentSupervisorPlaces[1];

            if (this.currentSupervisorPlaces.length == 2 && firstPlace.typeId == 1 && secondPlace.typeId == 2) {

                if (firstPlace.count != undefined) {
                    secondPlace.count = "-" + firstPlace.count;
                    secondPlace.unit = firstPlace.unit;
                }
                if (firstPlace.weight != undefined) {
                    secondPlace.weight = "-" + firstPlace.weight;
                    secondPlace.weightUnit = firstPlace.weightUnit;
                }
            }
            else if (firstPlace.typeId == 1 && this.currentSupervisorPlaces.slice(1).every((x: SupervisorPlaceRestModel) => { return x.typeId == 2 })) {
                const placeCountArray = [] as number[];
                const placeWeightArray = [] as number[];

                const placesWithoutFirstAndLast = this.currentSupervisorPlaces.slice(1, -1);
                placesWithoutFirstAndLast.forEach((x: SupervisorPlaceRestModel) => {
                    placeCountArray.push(Math.abs(Number(x.count)));
                    placeWeightArray.push(Math.abs(Number(x.weight)));

                    x.unit = firstPlace.unit;
                    x.weightUnit = firstPlace.weightUnit;
                })

                const lastPlace = this.currentSupervisorPlaces[this.currentSupervisorPlaces.length - 1];
                lastPlace.unit = firstPlace.unit;
                lastPlace.weightUnit = firstPlace.weightUnit;

                const everyElementInPlaceCountArrayIsNumber = placeCountArray.every((x: any) => {
                    return !isNaN(x);
                });
                if (everyElementInPlaceCountArrayIsNumber == true && !isNaN(Number(firstPlace.count))) {
                    lastPlace.count = "-" + (Number(firstPlace.count) - placesWithoutFirstAndLast.reduce((sum: number, current: SupervisorPlaceRestModel) => sum + Math.abs(Number(current.count)), 0)).toString();
                }

                const everyElementInPlaceWeightArrayIsNumber = placeWeightArray.every((x: any) => {
                    return !isNaN(x);
                });
                if (everyElementInPlaceWeightArrayIsNumber == true && !isNaN(Number(firstPlace.weight))) {
                    lastPlace.weight = "-" + (Number(firstPlace.weight) - placesWithoutFirstAndLast.reduce((sum: number, current: SupervisorPlaceRestModel) => sum + Math.abs(Number(current.weight)), 0)).toString();
                }
            }
        }
    }

    getCloseUnloadingsForLoading(place: SupervisorPlaceRestModel, index: number) {
        if (place.typeId == 1) {
            this.$store.direct.dispatch.supervisor.getCloseUnloadingsForLoading({ place: place });
        }
    }

    @Watch("occurredErrorWhileSaving")
    occurredErrorWhileSavingHandle(val: boolean) {
        if (val == true) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("common.errorWhileSaving")}`,
                    message: this.occurredErrorWhileSavingMessage,
                    links: [],
                    options: { buttonColor: "white", layoutColor: "error" },
                    buttonType: "ok"
                })
                .then(async confirm => {
                    if (confirm) {
                        this.occurredErrorWhileSaving = false;
                    }
                });
        }
    }

    @Watch("supervisorEditedByAnotherUser")
    supervisorEditedByAnotherUserHandle(val: boolean) {
        if (val == true) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("supervisor.supervisorEditedByAnotherUserTitle")}`,
                    message: `${this.$t("supervisor.supervisorEditedByAnotherUserMessage")}`,
                    links: [],
                    options: { buttonColor: "white", layoutColor: "error" },
                    buttonType: "ok"
                })
                .then(async confirm => {
                    if (confirm) {
                        this.supervisorEditedByAnotherUser = false;
                    }
                });
        }
    }

    @Watch("defaultLocationId")
    async defaultLocationIdHandle(val: number) {
        const currentBranchId = this.contractorAssets.departments.find((x: DepartmentItemRestModel) => {
            return x.value == this.defaultLocationId;
        })?.branchId;

        if (this.previousBranchId != currentBranchId && !this.isSupervisorInHistoricalState) {
            this.supervisor.locationId = this.defaultLocationId;
            await this.assignSupervisorOrderIdDueToBranchChanged(currentBranchId);
        }
    }

    @Watch("supervisor.isEditedByUserName", { deep: true })
    supervisorIsEditedByUserNameHandle(val: boolean) {
        if (this.supervisor.isEditedByUserName != null) {
            this.clearSupervisorLockedForEditingUntilValue();
        }
        else {
            this.updateSupervisorLockedForEditingUntilValue();
        }
    }

    async assignSupervisorOrderIdDueToBranchChanged(currentBranchId: number | undefined) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.changingTheBranchOfTheSupervisorTitle")}`,
                message: `${this.$t("supervisor.changingTheBranchOfTheSupervisorMessage")}`,
                links: [],
                options: { buttonColor: "white", layoutColor: "error" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.changeSupervisorBranchId();
                }
                else {
                    this.defaultLocationId = this.copyOfDefaultLocationId;
                }
            });
    }

    showContractorIsLockedAsClientDialog(contractor: ContractorDetailsForSupervisorRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("contractor.isLockedAsClientDialogTitle")}`,
                message: contractor.isLockedAsClientComment,
                links: [],
                options: { buttonColor: "white", layoutColor: "error" },
                buttonType: "ok"
            })
            .then(async confirm => {
                if (confirm) {
                    this.occurredErrorWhileSaving = false;
                }
            });
    }

    showContractorIsLockedAsCarrierDialog(contractor: ContractorDetailsForSupervisorRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("contractor.isLockedAsCarrierDialogTitle")}`,
                message: contractor.isLockedAsCarrierComment,
                links: [],
                options: { buttonColor: "white", layoutColor: "error" },
                buttonType: "ok"
            })
            .then(async confirm => {
                if (confirm) {
                    this.occurredErrorWhileSaving = false;
                }
            });
    }

    async created() {
        this.windowAddEventListener();

        this.$store.direct.dispatch.common.readLocalStorageApplicationStateData();
        this.integrationEnabled = false;
        this.encoryIntegrationEnabled = false;
        this.kpiEnabled = false;
        this.currentSupervisorCheckpoints = [];
        this.currentSupervisorPlaces = [];
        this.selectedContractorSender = { text: "", value: 0 } as ContractorSearchItemModel;
        this.selectedContractorCarrier = { text: "", value: 0 } as ContractorSearchItemModel;

        if (this.$route.params.id != undefined && this.$route.name != "Duplicate supervisor") {
            this.isEdited = true;
        }
        else {
            this.isFullDuplicating = this.$route.params.fullDuplication === "true" ? true : false;
        }

        if (this.supervisor.importantInfo != null && this.supervisor.importantInfo != undefined) {
            await this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("contractor.specialInfo")}`,
                    message: this.supervisor.importantInfo,
                    links: [],
                    options: { buttonColor: "primary", layoutColor: "primary" },
                    buttonType: "ok"
                })
                .then(async confirm => {
                    if (confirm) {
                        this.occurredErrorWhileSaving = false;
                    }
                });
        }

        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.supervisor.loadSupervisorAssets();
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.$store.direct.dispatch.common.loadVehicleTypeList({ source: "supervisor" });

        if (this.supervisor.customerCompanyId != undefined) {
            await this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: this.supervisor.customerCompanyId, contractorType: ContractorTypes.Client })
        }
        if (this.supervisor.shippingCompanyId != undefined) {
            this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: this.supervisor.shippingCompanyId, contractorType: ContractorTypes.Carrier })
        }
        if (this.supervisor.payerId != undefined) {
            this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: this.supervisor.payerId, contractorType: ContractorTypes.Payer })
        }
        if (this.supervisor.customerCompanyId != null && this.supervisor.customer != null) {
            this.selectedContractorSender = { text: this.supervisor.customer, value: this.supervisor.customerCompanyId, additionalInfo: this.supervisor.customerCompanyAdditionalInfo } as ContractorSearchItemModel;
        }
        else {
            this.selectedContractorSender = { text: this.supervisor.customer, value: 0, additionalInfo: "" };
        }
        if (this.supervisor.shippingCompanyId != null && this.supervisor.shippingCompany != null) {
            this.selectedContractorCarrier = { text: this.supervisor.shippingCompany, value: this.supervisor.shippingCompanyId, additionalInfo: this.supervisor.shippingCompanyAdditionalInfo } as ContractorSearchItemModel;
        }
        else {
            this.selectedContractorCarrier = { text: this.supervisor.shippingCompany, value: 0, additionalInfo: "" };
        }
        if (this.supervisor.payerId != null && this.supervisor.payerName != null) {
            this.selectedContractorPayer = { text: this.supervisor.payerName, value: this.supervisor.payerId, additionalInfo: "" } as ContractorSearchItemModel;
        }
        else {
            this.selectedContractorPayer = { text: this.supervisor.payerName, value: 0, additionalInfo: "" };
        }

        if (this.supervisor.customerCompanyCustomInfoToSupervisor != null && this.supervisor.customerCompanyCustomInfoToSupervisor != undefined) {
            await this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("supervisor.importantInfoAboutContractor")}`,
                    message: this.supervisor.customerCompanyCustomInfoToSupervisor,
                    links: [],
                    options: { buttonColor: "primary", layoutColor: "primary" },
                    buttonType: "ok"
                })
                .then(async confirm => {
                    if (confirm) {
                        this.occurredErrorWhileSaving = false;
                    }
                });
        }

        if (this.supervisor.locationId == 8) {
            await this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("supervisor.needChangingSupervisorDepartmentDialogTitle")}`,
                    message: `${this.$t("supervisor.needChangingSupervisorDepartmentDialogMessage")}`,
                    links: [],
                    options: { buttonColor: "primary", layoutColor: "primary" },
                    buttonType: "ok"
                })
                .then(async confirm => {
                    if (confirm) {
                        this.occurredErrorWhileSaving = false;
                    }
                });
        }

        this.$store.direct.dispatch.supervisor.loadSupervisorAttachmentList();
        this.$store.direct.dispatch.supervisor.loadSupervisorProcedureList();
    }

    mounted() {
        if (this.$refs.form != undefined) {
            const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        }
        if (this.isDroppedFromPreliminaryList == true) {
            this.supervisor.isPreliminary = false;
        }
    }

    windowAddEventListener() {
        window.addEventListener('beforeunload', (e) => {
            this.clearSupervisorLockedForEditingUntilValue();
        });
        window.addEventListener('unload', (e) => {
            this.clearSupervisorLockedForEditingUntilValue();
        });
        window.addEventListener('onbeforeunload', (e) => {
            this.clearSupervisorLockedForEditingUntilValue();
        });
        window.addEventListener('onunload', (e) => {
            this.clearSupervisorLockedForEditingUntilValue();
        });
    }

    clearDateTimePickerTimeTo(index: number) {
        this.currentSupervisorPlaces[index].timeTo = "";
    }

    showSupervisorIsEditedByAnotherUserDialog(isEditedByUserName: string) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.orderIsCurrentlyBeingUsed")}`,
                message: `${this.$t("supervisor.orderIsCurrentlyBeingUsedBy")}` + isEditedByUserName,
                links: [],
                options: { buttonColor: "error", layoutColor: "error" },
                buttonType: "ok"
            });
    }

    updateSupervisorLockedForEditingUntilValueIsSet = false;
    updateSupervisorLockedForEditingUntilValue() {
        if (this.updateSupervisorLockedForEditingUntilValueIsSet == false) {
            this.updateSupervisorLockedForEditingUntilValueIsSet = true;
            this.updateSupervisorLockedForEditingUntilValueIntervalId = setInterval(() => {
                this.$store.direct.dispatch.supervisor.updateSupervisorLockedForEditingUntilValue({ supervisorId: this.supervisor.id });
            }, 20000);
        }
    }

    clearSupervisorLockedForEditingUntilValue() {
        if (!this.supervisorIsEditedByAnotherUser) {
            clearInterval(this.updateSupervisorLockedForEditingUntilValueIntervalId);
            this.$store.direct.dispatch.supervisor.clearSupervisorLockedForEditingUntilValue({ supervisorId: this.supervisor.id });
        }
    }

    async supervisorPlacePostalCodeOrCountryChanged(place: SupervisorPlaceRestModel, index: number) {
        if (this.supervisorPlaceHasCountryAndPostalCode(place)) {
            await this.$store.direct.dispatch.common.searchAddresses({ searchText: place.postalCode, additionalSearchText: place.country });
            if (this.addressFound.length > 0) {
                place.lat = this.addressFound[0]?.lat as number;
                place.lng = this.addressFound[0].lng as number;
                place.isLocationConfirmedByUser = true;
            }
            else {
                place.lat = undefined;
                place.lng = undefined;
                place.isLocationConfirmedByUser = false;
            }
            this.$forceUpdate();

            this.getDistanceBetweenSupervisorPlaces();
            this.changeNeedRecalculatingFreigntValue();
        }
    }

    supervisorPlaceHasCountryAndPostalCode(place: SupervisorPlaceRestModel) {
        const result = place.postalCode != null && place.postalCode.length > 3 && place.country != null && place.country.length > 1;
        return result;
    }

    async getDistanceBetweenSupervisorPlaces() {
        const allPlacesAreFilled = this.currentSupervisorPlaces.every((x: SupervisorPlaceRestModel) => {
            return x.lat != undefined && x.lng != undefined;
        });

        if (allPlacesAreFilled) {
            const placesCoordinates = this.currentSupervisorPlaces.map((x: SupervisorPlaceRestModel) => {
                return { lat: x.lat, lng: x.lng } as any;
            });
            const distance = await this.$store.direct.dispatch.common.getDistanceBetweenPlaces({ placesCoordinates: placesCoordinates });

            const doNotShowKilometersHint = this.currentSupervisorPlaces.some(place => place.country == "IT");
            if (!doNotShowKilometersHint) {
                this.supervisorKilometersHints = distance.toString();
            }
            else {
                this.supervisorKilometersHints = "";
            }
        }

    }

    placeItemSearch(place: SupervisorPlaceRestModel, index: number, searchTextType: string) {
        if (!this.localStorageApplicationStateData.supervisorEditingIsPlaceSuggestionsOn) {
            return;
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            let currentPlace = this.currentSupervisorPlaces.find(x => { return x == place })
            if (currentPlace == undefined) {
                currentPlace = {
                    searchName: "",
                    searchPostalCode: "",
                    searchCity: "",
                } as SupervisorPlaceRestModel;
            }
            switch (searchTextType) {
                case "name":
                    {
                        this.$store.direct.dispatch.supervisor.searchSupervisorPlaceItems({ searchText: currentPlace.searchName, searchTextType: searchTextType });
                        break;
                    }
                case "postalCode":
                    {
                        this.$store.direct.dispatch.supervisor.searchSupervisorPlaceItems({ searchText: currentPlace.searchPostalCode, searchTextType: searchTextType });
                        break;
                    }
                case "city":
                    {
                        this.$store.direct.dispatch.supervisor.searchSupervisorPlaceItems({ searchText: currentPlace.searchCity, searchTextType: searchTextType });
                        break;
                    }
            }
        }, 1000);
    }

    handlePlaceNameInput(e: SupervisorPlaceRestModel) {
        if (typeof e == 'string') {
            const placeFound = this.placeNamesFound.find(x => x.additionalInfo == e);
            if (placeFound != undefined) {
                const placeFoundFromSupervisorPlaces = this.currentSupervisorPlaces.find(x => x.name == placeFound?.additionalInfo);
                const index = this.currentSupervisorPlaces.indexOf(placeFoundFromSupervisorPlaces as SupervisorPlaceRestModel)
                this.fillSupervisorPlaceFields(index, placeFound);
            }
        }
    }

    handlePlacePostalCodeInput(e: SupervisorPlaceRestModel) {
        if (typeof e == 'string') {
            const placeFound = this.placePostalCodesFound.find(x => x.additionalInfo == e);
            if (placeFound != undefined) {
                const placeFoundFromSupervisorPlaces = this.currentSupervisorPlaces.find(x => x.postalCode == placeFound?.additionalInfo);
                const index = this.currentSupervisorPlaces.indexOf(placeFoundFromSupervisorPlaces as SupervisorPlaceRestModel)
                this.fillSupervisorPlaceFields(index, placeFound);
            }
        }
    }

    handlePlaceCityInput(e: SupervisorPlaceRestModel) {
        if (typeof e == 'string') {
            const placeFound = this.placeCitiesFound.find(x => x.additionalInfo == e);
            if (placeFound != undefined) {
                const placeFoundFromSupervisorPlaces = this.currentSupervisorPlaces.find(x => x.city == placeFound?.additionalInfo);
                const index = this.currentSupervisorPlaces.indexOf(placeFoundFromSupervisorPlaces as SupervisorPlaceRestModel)
                this.fillSupervisorPlaceFields(index, placeFound);
            }
        }
    }

    fillSupervisorPlaceFields(index: number, placeFound: SupervisorPlaceRestModel) {
        setTimeout(() => {
            this.currentSupervisorPlaces[index].name = placeFound?.name as string;
            this.currentSupervisorPlaces[index].country = placeFound?.country as string;
            this.currentSupervisorPlaces[index].postalCode = placeFound?.postalCode as string;
            this.currentSupervisorPlaces[index].city = placeFound?.city as string;
            this.currentSupervisorPlaces[index].street = placeFound?.street as string;
            this.currentSupervisorPlaces[index].gpsCoordinates = placeFound?.gpsCoordinates as string;
            this.currentSupervisorPlaces[index].lat = placeFound?.lat as number;
            this.currentSupervisorPlaces[index].lng = placeFound?.lng as number;
            this.currentSupervisorPlaces[index].isLocationConfirmedByUser = true;

            this.supervisorPlacePostalCodeOrCountryChanged(this.currentSupervisorPlaces[index], index);
        }, 1);
    }

    formatDateTime(list: any, prop: string): any {
        return list.map((item: any) => {
            if (item[prop]) {
                item[prop] = dayjs(item[prop]).format("YYYY-MM-DD HH:mm");
            }
            return item;
        });
    };

    formatSingleDateTimeValue(time: any) {
        return time != null ? dayjs(time).format("YYYY-MM-DD HH:mm") : "";
    }

    openGoogleMapsURL(lat: number, lng: number) {
        window.open("https://www.google.com/maps?q=" + lat + "," + lng, '_blank');
    }

    prepareValueForPlaceStampField(hasStamp: boolean) {
        return hasStamp ? this.$t('common.yes') : this.$t('common.no')
    }

    setIconColor(index: number): boolean {
        const currentPlace = this.currentSupervisorPlaces[index];
        if (currentPlace.isLocationConfirmedByUser == true && currentPlace.lat != null && currentPlace.lng != null && currentPlace.lat != 0 && currentPlace.lng != 0) {
            return true;
        }
        return false;
    }

    confirmCheckpointIsVisibleForContractor(checkpoint: SupervisorCheckpointRestModel) {
        if (checkpoint.isVisibleForContractor == true) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("supervisor.visibleForContractor")}`,
                    message: `${this.$t("supervisor.visibleForContractorMessage")}`,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                })
        }
    }

    openLocationDialog(index: number) {
        if (!this.isSupervisorInHistoricalState) {
            this.$store.direct.commit.supervisor.setSupervisorPlace(this.currentSupervisorPlaces[index])
            this.$store.direct.dispatch.locationDialog
                .openDialog({
                    showDialog: true,
                    title: `${this.$t("supervisor.setLocation")}`
                })
                .then(async confirm => {
                    if (confirm) {
                        this.currentSupervisorPlaces[index].postalCode = this.supervisorPlace.postalCode;
                        this.currentSupervisorPlaces[index].lat = this.supervisorPlace.lat;
                        this.currentSupervisorPlaces[index].lng = this.supervisorPlace.lng;
                        this.currentSupervisorPlaces[index].isLocationConfirmedByUser = true;
                        this.$forceUpdate();
                    }
                });
        }
    }

    async downloadFile(fileId: number) {
        if (this.isSupervisorInHistoricalState) {
            await this.$store.direct.dispatch.supervisor.downloadFile({ fileId: fileId, forSupervisorMemento: true });
        }
        else {
            await this.$store.direct.dispatch.supervisor.downloadFile({ fileId: fileId, forSupervisorMemento: false });
        }
    }

    addNewPlaceForm() {
        if (this.isEdited == false && this.currentSupervisorPlaces.length == 0) {
            this.currentSupervisorPlaces.push({
                typeId: 1,
                time: "0001-01-01 00:00",
                unit: "palette",
                weightUnit: "kg",
            } as SupervisorPlaceRestModel);
            this.currentSupervisorPlaces.push({
                typeId: 2,
                time: "0001-01-01 00:00",
                unit: "palette",
                weightUnit: "kg",
            } as SupervisorPlaceRestModel);
        }
        else {
            this.currentSupervisorPlaces.push({
                typeId: 1,
                time: "0001-01-01 00:00",
                unit: "palette",
                weightUnit: "kg",
            } as SupervisorPlaceRestModel);
        }
    }

    removePlaceForm(index: number) {
        this.selectedPlacesIdcsForCreatingNewSupervisor.splice(index, 1);
        this.currentSupervisorPlaces[index].isDeleted = true;
        this.currentSupervisorPlaces.splice(index, 1);
    }

    addNewCheckpointForm() {
        this.currentSupervisorCheckpoints.push({
            isChecked: false,
            checkTime: dayjs().format("YYYY-MM-DD HH:mm"),
        } as SupervisorCheckpointRestModel);
    }

    removeCheckpointForm(index: number) {
        this.currentSupervisorCheckpoints[index].isDeleted = true;
        this.currentSupervisorCheckpoints.splice(index, 1);
    }

    changeSupervisorPropertiesToUpperCase() {
        if (this.supervisor.registrationNumber != null && this.supervisor.registrationNumber != undefined) {
            this.supervisor.registrationNumber = this.supervisor.registrationNumber.replace(/\s/g, "");
            this.supervisor.registrationNumber = this.supervisor.registrationNumber.toUpperCase();
        }

        this.supervisor.saleValueCurrency = (this.supervisor.saleValueCurrency != null && this.supervisor.saleValueCurrency != undefined) ? this.supervisor.saleValueCurrency.toUpperCase() : this.supervisor.saleValueCurrency;
        this.supervisor.purchaseValueCurrency = (this.supervisor.purchaseValueCurrency != null && this.supervisor.purchaseValueCurrency != undefined) ? this.supervisor.purchaseValueCurrency.toUpperCase() : this.supervisor.purchaseValueCurrency;

        if (this.supervisor.driver1Name != null && this.supervisor.driver1Name != undefined) {
            if (this.supervisor.driver1Name.length == 0) {
                this.supervisor.driver1Name = undefined as any;
            }
            else {
                this.supervisor.driver1Name = this.supervisor.driver1Name.toUpperCase();
            }
        }

        if (this.supervisor.driver1Telephone != null && this.supervisor.driver1Telephone != undefined) {
            if (this.supervisor.driver1Telephone.length == 0) {
                this.supervisor.driver1Telephone = undefined as any;
            }
            else {
                this.supervisor.driver1Telephone = this.supervisor.driver1Telephone.toUpperCase();
            }
        }

        if (this.supervisor.driver2Name != null && this.supervisor.driver2Name != undefined) {
            if (this.supervisor.driver2Name.length == 0) {
                this.supervisor.driver2Name = undefined as any;
            }
            else {
                this.supervisor.driver2Name = this.supervisor.driver2Name.toUpperCase();
            }
        }

        if (this.supervisor.driver2Telephone != null && this.supervisor.driver2Telephone != undefined) {
            if (this.supervisor.driver2Telephone.length == 0) {
                this.supervisor.driver2Telephone = undefined as any;
            }
            else {
                this.supervisor.driver2Telephone = this.supervisor.driver2Telephone.toUpperCase();
            }
        }

        this.currentSupervisorPlaces.forEach((x: SupervisorPlaceRestModel) => {
            x.country = (x.country != null && x.country != undefined) ? x.country.toUpperCase() : x.country;
        });
    }

    async showKpiCheckingNeededDialog() {
        if (this.kpiEnabled) {
            const placesResult = this.currentSupervisorPlaces.every((x: SupervisorPlaceRestModel) => {
                return x.isChecked;
            });
            const checkpointResult = this.currentSupervisorCheckpoints.every((x: SupervisorCheckpointRestModel) => {
                return x.isChecked;
            });

            if (placesResult && checkpointResult) {
                const notAllKpiAreSet = this.supervisor.kpiReceiptDate == null || this.supervisor.kpiRepackaging == null || this.supervisor.kpiDocumentGeneration == null || this.supervisor.kpiFerryBoats == null || this.supervisor.kpiCustomDuties == null || this.supervisor.kpiLifts == null || this.supervisor.kpiStornos == null || this.supervisor.kpiAdditionalLoadingPoint == null;

                if (notAllKpiAreSet) {
                    await this.$store.direct.dispatch.confirm
                        .openConfirmDialog({
                            showConfirmDialog: true,
                            title: `${this.$t("supervisor.kpiCheckingNeededTitle")}`,
                            message: `${this.$t("supervisor.kpiCheckingNeededMessage")}`,
                            links: [],
                            options: { buttonColor: "error", layoutColor: "error" },
                            buttonType: "ok"
                        });
                }
            }
        }
    }

    async saveSupervisorClick(backToSupervisorList: boolean = true): Promise<void> {
        await this.showKpiCheckingNeededDialog();

        this.changeSupervisorPropertiesToUpperCase();

        if (this.supervisor.importantInfo != null && this.supervisor.importantInfo.length == 0) {
            this.supervisor.importantInfo = null as any;
        }

        const tempSelectedContractorSender: ContractorSearchItemModel | string = this.selectedContractorSender;
        const tempSelectedContractorCarrier: ContractorSearchItemModel | string = this.selectedContractorCarrier;
        const tempSelectedContractorPayer: ContractorSearchItemModel | string = this.selectedContractorPayer;

        if (tempSelectedContractorSender != null) {
            if (tempSelectedContractorSender.value != undefined && tempSelectedContractorSender.value != 0) {
                this.supervisor.customerCompanyId = tempSelectedContractorSender.value;
                this.supervisor.customer = tempSelectedContractorSender.text;
            }
            else {
                this.supervisor.customerCompanyId = null;
                if (typeof (this.selectedContractorSender) === "string") {
                    this.supervisor.customer = tempSelectedContractorSender as unknown as string;
                }
            }
        }
        else {
            this.supervisor.customerCompanyId = null;
            this.supervisor.customer = null as any;
        }

        if (tempSelectedContractorCarrier != null) {
            if (tempSelectedContractorCarrier.value != undefined && tempSelectedContractorCarrier.value != 0) {
                this.supervisor.shippingCompanyId = tempSelectedContractorCarrier.value;
                this.supervisor.shippingCompany = tempSelectedContractorCarrier.text;
            }
            else {
                this.supervisor.shippingCompanyId = null;
                if (typeof (this.selectedContractorCarrier) === "string") {
                    this.supervisor.shippingCompany = tempSelectedContractorCarrier as unknown as string;
                }
            }
        }
        else {
            this.supervisor.shippingCompanyId = null;
            this.supervisor.shippingCompany = null as any;
        }

        if (tempSelectedContractorPayer != null && tempSelectedContractorPayer.value != undefined && tempSelectedContractorPayer.value != 0) {
            this.supervisor.payerId = tempSelectedContractorPayer.value;
            this.supervisor.payerName = tempSelectedContractorPayer.text;
        }
        else {
            this.supervisor.payerId = null;
            this.supervisor.payerName = null as any;
        }

        this.currentSupervisorPlaces.forEach((x: SupervisorPlaceRestModel) => {
            if (x.typeId == 5) {
                x.count = "";
                x.unit = "";
                x.weight = "";
                x.weightUnit = "";
            }
            else {
                x.whoPaysForCustomClearanceTypeId = undefined;
            };
        });

        this.supervisor.checkpoints = this.currentSupervisorCheckpoints;
        this.supervisor.places = this.currentSupervisorPlaces;
        this.supervisor.locationId = this.defaultLocationId;

        this.supervisor.goodsAreSubjectToCustomClearance = !this.supervisor.places.every((x: SupervisorPlaceRestModel) => {
            return x.typeId != 5;
        });

        if (this.supervisor.shippingCompanyId != null) {
            this.contractorCarrierPolicyIsActiveResult = await this.checkIfContractorCarrierPolicyIsActive(this.supervisor);
        }
        else {
            this.contractorCarrierPolicyIsActiveResult.policyIsActive = true;
        }

        if (this.supervisor.customerCompanyId != null) {
            await this.$store.direct.dispatch.contractor.checkIfContractorHasExceededDebtLimit({ contractorId: this.supervisor.customerCompanyId, contractorType: ContractorTypes.Client });
        }

        if (this.supervisor.payerId != null) {
            await this.$store.direct.dispatch.contractor.checkIfContractorHasExceededDebtLimit({ contractorId: this.supervisor.payerId, contractorType: ContractorTypes.Payer });
        }

        let freightValueIsCorrect = true;
        let freightValueIsIncorrectMessage = "";
        const regExp = /[a-zA-Z]/g;

        if (this.supervisor.saleValue != null && regExp.test(this.supervisor.saleValue.toString())) {
            freightValueIsCorrect = false;
            freightValueIsIncorrectMessage = `${this.$t("supervisor.customerFreight")}` + " value is incorrect"
        }
        if (this.supervisor.purchaseValue != null && regExp.test(this.supervisor.purchaseValue.toString())) {
            freightValueIsCorrect = false;
            freightValueIsIncorrectMessage = `${this.$t("supervisor.forwarderFreight")}` + " value is incorrect"
        }

        if (this.supervisor.customerCompanyId != null && this.checkIfContractorClientHasExceededDebtLimit == true) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("contractor.contractorHasExceededDebtLimit")}`,
                    message: `${this.$t("contractor.contractorHasExceededDebtLimit")}` + ": " + this.supervisor.customer,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                })
            return;
        }

        if (this.supervisor.payerId != null && this.checkIfContractorPayerHasExceededDebtLimit == true) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("contractor.contractorHasExceededDebtLimit")}`,
                    message: `${this.$t("contractor.contractorHasExceededDebtLimit")}` + ": " + this.supervisor.payerName,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                })
            return;
        }

        if (this.supervisor.shippingCompanyId != null && this.contractorCarrierPolicyIsActiveResult.policyIsActive == false) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("supervisor.contractorCarrierPolicyIsNotActive")}`,
                    message: `${this.$t("supervisor.contractorCarrierPolicyIsNotActive")}` + ": " + this.contractorCarrierPolicyIsActiveResult.checkedPolicyType,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                })
        }
        else if (freightValueIsCorrect == false) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: freightValueIsIncorrectMessage,
                    message: freightValueIsIncorrectMessage,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                })
        }
        else {
            if (this.supervisor.saleValue != null) {
                this.supervisor.saleValue = parseFloat(this.supervisor.saleValue.toString().replace(',', '.'));
            }
            if (this.supervisor.purchaseValue) {
                this.supervisor.purchaseValue = parseFloat(this.supervisor.purchaseValue.toString().replace(',', '.'));
            }
            if (this.isDroppedFromPreliminaryList) {
                this.canChangeRouteToSupervisorList = await this.$store.direct.dispatch.supervisor.saveSupervisor({ addingSupervisorFromPreliminary: true }) as boolean;
            }
            else {
                this.canChangeRouteToSupervisorList = await this.$store.direct.dispatch.supervisor.saveSupervisor() as boolean;
            }

            if (this.$refs.form != undefined) {
                const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
            }

            if (Object.keys(this.validationError).length === 0 && this.canChangeRouteToSupervisorList == true && backToSupervisorList == true) {
                this.backToList();
            }
        }
    }

    async saveSupervisorAndSendSupervisorCarrierDocumentEmailClick() {
        this.canChangeRouteToSupervisorList = false;

        if (!this.supervisor.userOpenedSupervisorInSafeLocation) {
            await this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("common.youAreNotInSafeLocationOrYouDidNotOpenOrderInSafeLocationTitle")}`,
                    message: `${this.$t("common.youAreNotInSafeLocationOrYouDidNotOpenOrderInSafeLocationMessage")}`,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });

            this.canChangeRouteToSupervisorList = true;
        }
        else {
            await this.saveSupervisorClick(false);
            this.canChangeRouteToSupervisorList = true;
            this.$store.direct.commit.supervisor.setSendingEmailDialogEmailNote("");

            if (Object.keys(this.validationError).length === 0 && this.contractorCarrierPolicyIsActiveResult.policyIsActive == true && this.supervisor.userOpenedSupervisorInSafeLocation) {
                this.$store.direct.dispatch.supervisor.getSupervisorCarrierDocumentOrderRequirementList({ forSupervisor: true });
                this.$store.direct.dispatch.common.loadDispoEmailAddressList();
                this.$store.direct.dispatch.contractor.loadContractorAssets();
                this.$store.direct.dispatch.supervisor.loadSupervisorAssets();
                this.$store.direct.commit.supervisor.setSendingEmailDialogSendToEmailAddressArray([{ emailAddress: "" }]);
                this.$store.direct.dispatch.supervisor
                    .openSendingEmailDialog({
                        showDialog: true,
                        title: `${this.$t("supervisor.saveAndSendEmailToCarrier")}`
                    })
                    .then(async confirm => {
                        if (confirm) {
                            await this.$store.direct.dispatch.supervisor.sendSupervisorCarrierDocumentEmail({ supervisorId: this.supervisor.id, contractorCarrierId: this.contractorCarrierDetailsForSupervisor.id });
                            this.backToList();
                        }
                        else {
                            await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: this.supervisor.id });
                        }
                    })
            }
        }
    }

    async checkIfContractorCarrierPolicyIsActive(supervisor: SupervisorRestModel): Promise<contractorCarrierPolicyIsActiveResultInterface> {
        const supervisorPlacesPlanningTimeSortedArray = supervisor.places.map(x => x.time).sort();
        const lastSupervisorPlacesPlanningTime = supervisorPlacesPlanningTimeSortedArray[supervisorPlacesPlanningTimeSortedArray.length - 1];

        if (supervisor.places.length == 0) {
            //if no places exist return true which means that carrier has active policy
            return { policyIsActive: true };
        }
        else if (supervisor.places.every(x => x.country == this.contractorCarrierDetailsForSupervisor.country)) {
            await this.$store.direct.dispatch.contractor.checkIfContractorPolicyIsActive({ contractorId: supervisor.shippingCompanyId as number, contractorPolicyTypeId: 0, checkDate: lastSupervisorPlacesPlanningTime });
            return { policyIsActive: this.checkIfContractorPolicyIsActive, checkedPolicyType: "National" };
        }
        else if (supervisor.places.every(x => x.country == supervisor.places[0].country) && supervisor.places[0].country != this.contractorCarrierDetailsForSupervisor.country) {
            await this.$store.direct.dispatch.contractor.checkIfContractorPolicyIsActive({ contractorId: supervisor.shippingCompanyId as number, contractorPolicyTypeId: 2, checkDate: lastSupervisorPlacesPlanningTime });
            return { policyIsActive: this.checkIfContractorPolicyIsActive, checkedPolicyType: "Cabotage" };
        }
        else {
            await this.$store.direct.dispatch.contractor.checkIfContractorPolicyIsActive({ contractorId: supervisor.shippingCompanyId as number, contractorPolicyTypeId: 1, checkDate: lastSupervisorPlacesPlanningTime });
            return { policyIsActive: this.checkIfContractorPolicyIsActive, checkedPolicyType: "International" };
        }
    }

    async addNewContractorCarrier() {
        await this.$store.direct.dispatch.contractor.loadContractor({ types: [ContractorTypes.Carrier] });
        await this.$store.direct.dispatch.contractor.loadPolicy();
        this.$store.direct.dispatch.supervisor
            .openContractorDialog({
                showDialog: true,
                title: `${this.$t("contractor.createNewContractorTitle")}`,
                contractorTypes: [ContractorTypes.Carrier],
                isContractorEdited: false,
            })
            .then(async confirm => {
                if (confirm) {
                    this.contractorCarrierSearchLoadItems(this.contractorCarrierSearch);
                }
            });
    }

    async editContractorCarrier() {
        await this.$store.direct.dispatch.contractor.loadContractor({ id: this.selectedContractorCarrier.value.toString() });
        await this.$store.direct.dispatch.contractor.loadPolicyList();
        this.$store.direct.dispatch.supervisor
            .openContractorDialog({
                showDialog: true,
                title: `${this.$t("contractor.editContractorTitle")}`,
                contractorTypes: [ContractorTypes.Carrier],
                isContractorEdited: true,
            })
            .then(async confirm => {
                if (confirm) {
                    this.contractorCarrierSearchLoadItems(this.contractorCarrierSearch);
                }
            });
    }

    async editContractorSender() {
        await this.$store.direct.dispatch.contractor.loadContractor({ id: this.selectedContractorSender.value.toString() });
        this.$store.direct.dispatch.supervisor
            .openContractorDialog({
                showDialog: true,
                title: `${this.$t("contractor.editContractorTitle")}`,
                contractorTypes: [ContractorTypes.Client],
                isContractorEdited: true,
            })
            .then(async confirm => {
                if (confirm) {
                    this.contractorSenderSearchLoadItems(this.contractorSenderSearch);
                }
            });
    }

    async addNewContractorSender() {
        await this.$store.direct.dispatch.contractor.loadContractor({ types: [ContractorTypes.Client] });
        this.$store.direct.dispatch.supervisor
            .openContractorDialog({
                showDialog: true,
                title: `${this.$t("contractor.createNewContractorTitle")}`,
                contractorTypes: [ContractorTypes.Client],
                isContractorEdited: false,
            })
            .then(async confirm => {
                if (confirm) {
                    this.contractorSenderSearchLoadItems(this.contractorSenderSearch);
                }
            });
    }

    async addNewCustomerContactPerson() {
        this.$store.direct.commit.contractor.setContractor({ id: this.selectedContractorSender.value } as ContractorRestModel);
        await this.$store.direct.dispatch.supervisor
            .openContractorContactPersonDialog({
                showDialog: true,
            })
            .then(async confirm => {
                if (confirm) {
                    await this.selectedContractorSenderChanged();
                }
            });
    }

    showClearCustomerContactPersonDialog() {
        const customerContactPerson = this.supervisor.customerContactPerson;

        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.clearCustomerContactPersonTitle")}`,
                message: `${this.$t("supervisor.clearCustomerContactPersonMessage")}`,
                links: [],
                options: { buttonColor: "error", layoutColor: "error" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    this.supervisor.customerContactPerson = {} as ListItemNumberModel;
                }
                else {
                    this.supervisor.customerContactPerson = customerContactPerson;
                }
            });
    }

    async mobileAppCancelOrder() {
        if (!this.isSupervisorInHistoricalState) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("supervisor.cancelOrderTitle")}`,
                    message: `${this.$t("supervisor.cancelOrderMessage")}`,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "yes/no"
                })
                .then(async confirm => {
                    if (confirm) {
                        await this.$store.direct.dispatch.supervisor.mobileAppCancelOrder({ supervisorId: this.supervisor.id });
                        await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: this.supervisor.id });
                    }
                });
        }
    }

    async mobileAppOrderItemStateChange(supervisorPlaceId: number) {
        if (!this.isSupervisorInHistoricalState) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("supervisor.confirmOrderTitle")}`,
                    message: `${this.$t("supervisor.confirmOrderMessage")}`,
                    links: [],
                    options: { buttonColor: "primary", layoutColor: "primary" },
                    buttonType: "yes/no"
                })
                .then(async confirm => {
                    if (confirm) {
                        await this.$store.direct.dispatch.supervisor.mobileAppOrderItemStateChange({ supervisorId: this.supervisor.id, supervisorPlaceId: supervisorPlaceId });
                        await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: this.supervisor.id });
                    }
                });
        }
    }

    async integrationClick() {
        if (this.isSupervisorInHistoricalState) {
            await this.$store.direct.dispatch.supervisor.getSupervisorIntegrations({ supervisorId: this.supervisor.supervisorMementoId, forSupervisorMemento: true });
        }
        else {
            await this.$store.direct.dispatch.supervisor.getSupervisorIntegrations({ supervisorId: this.supervisor.id, forSupervisorMemento: false });
        }
        this.openIntegrationDialog();
    }

    async encoryIntegrationClick() {
        if (this.isSupervisorInHistoricalState) {
            await this.$store.direct.dispatch.supervisorEncory.getSupervisorEncoryIntegrations({ supervisorId: this.supervisor.supervisorMementoId, forSupervisorMemento: true });
        }
        else {
            await this.$store.direct.dispatch.supervisorEncory.getSupervisorEncoryIntegrations({ supervisorId: this.supervisor.id, forSupervisorMemento: false });
        }
        this.openEncoryIntegrationDialog();
    }

    openIntegrationDialog() {
        this.$store.direct.dispatch.supervisor
            .openIntegrationDialog({
                showDialog: true,
                title: `${this.$t("supervisor.integration")}`
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.updateSupervisorIntegrations()
                    await this.$store.direct.dispatch.supervisor.reloadSupervisorEditedAtValue({ id: this.supervisor.id });
                }
            })
    }

    openEncoryIntegrationDialog() {
        this.$store.direct.dispatch.supervisorEncory
            .openEncoryIntegrationDialog({
                showDialog: true,
                title: `${this.$t("supervisor.encoryIntegration")}`
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisorEncory.updateSupervisorEncoryIntegrations()
                    await this.$store.direct.dispatch.supervisor.reloadSupervisorEditedAtValue({ id: this.supervisor.id });
                }
            })
    }

    async attachmentCreateClick() {
        await this.$store.direct.dispatch.supervisor.openAttachmentDialog()
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.reloadSupervisorEditedAtValue({ id: this.supervisor.id });
                }
            });
    }

    async procedureCreateClick() {
        await this.$store.direct.commit.supervisor.setProcedure({} as SupervisorProcedureRestModel);
        await this.$store.direct.commit.supervisor.setProcedureFileList([] as ListItemNumberModel[]);
        await this.$store.direct.dispatch.supervisor.openProcedureDialog()
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.reloadSupervisorEditedAtValue({ id: this.supervisor.id });
                }
            });
    }

    loadProcedure(procedureId: number) {
        this.$store.direct.dispatch.supervisor.loadProcedure({ procedureId: procedureId });
        this.$store.direct.dispatch.supervisor.openProcedureDialog()
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.reloadSupervisorEditedAtValue({ id: this.supervisor.id });
                }
            });
    }

    async deleteSuperviorProcedure(item: SupervisorProcedureRestModel) {
        await this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: this.$t("supervisor.deleteSupervisorProcedureTitle").toString(),
                message: this.$t("supervisor.deleteSupervisorProcedureMessage").toString() + ": " + item.vin,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.deleteSuperviorProcedure({ procedureId: item.id });
                    await this.$store.direct.dispatch.supervisor.loadSupervisorProcedureList();
                }
            })
    }

    async downloadSuperviorProcedureDocument(item: SupervisorProcedureRestModel) {
        await this.$store.direct.dispatch.supervisor.downloadSupervisorProcedureDocument({ procedureId: item.id });
    }

    openCargoSupervisorListClick() {
        this.backToList();
    }

    archiveSupervisor() {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.archive")}: "${this.supervisor.orderId}"`,
                message: `${this.$t("supervisor.archiveMessage")}`,
                links: [],
                options: { buttonColor: "primary", layoutColor: "primary" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    this.canChangeRouteToSupervisorList = false;
                    await this.saveSupervisorClick(true);

                    if (Object.keys(this.validationError).length === 0 && this.contractorCarrierPolicyIsActiveResult.policyIsActive == true) {
                        const result = await this.$store.direct.dispatch.supervisor.changeSupervisorArchiveValue({ supervisorId: this.supervisor.id, isArchived: true });
                        if (result == true) {
                            this.backToList();
                        }
                        else {
                            await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: this.supervisor.id });
                        }
                    }
                    this.canChangeRouteToSupervisorList = true;
                }
            });
    }

    copyPlaceAddress(index: number) {
        const place = this.currentSupervisorPlaces[index];
        const placeAddress = place.name + ", " + place.street + ", " + place.country + " " + place.postalCode + ", " + place.city;
        const textarea = document.createElement('textarea');
        textarea.value = placeAddress;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }

    async generateTransportDocument(index: number) {
        this.currentSupervisorLoadingPlaceId = this.currentSupervisorPlaces[index].id;
        this.currentSupervisorUnloadingPlaceId = this.currentSupervisorPlaces[this.currentSupervisorPlaces.length - 1].id;

        await this.$store.direct.dispatch.supervisorTransportDocument
            .openDialog({
                showDialog: true,
                title: `${this.$t("supervisorTransportDocument.dialogTitle")}`
            })
    }

    convertFirstPlaceWeight(place: SupervisorPlaceRestModel, outputUnit: string): number {
        if (outputUnit == "t") {
            if (place.weightUnit == "t") {
                return parseFloat(place.weight);
            }
            else if (place.weightUnit == "kg") {
                const weightAsNumber = parseFloat(place.weight);
                return weightAsNumber / 1000;
            }
            else {
                return 0;
            }
        }
        else if (outputUnit == "kg") {
            if (place.weightUnit == "t") {
                const weightAsNumber = parseFloat(place.weight);
                return weightAsNumber * 1000;
            }
            else if (place.weightUnit == "kg") {
                return parseFloat(place.weight);
            }
        }
        return 0;
    }

    vehicleSize = "";
    required_truck_bodies = [] as string[];
    generateRequiredVehicleSizeAndTruckBodiesFromVehicleType() {
        switch (this.supervisor.vehicleType) {
            case "caddy": {
                this.vehicleSize = "bus";
                this.required_truck_bodies = ["open-box"];
                break;
            }
            case "sprinter": {
                this.vehicleSize = "bus";
                this.required_truck_bodies = ["open-box"];
                break;
            }
            case "luton van": {
                this.vehicleSize = "bus";
                this.required_truck_bodies = ["curtainsider", "standard-tent"];
                break;
            }
            case "7,5t": {
                this.vehicleSize = "solo";
                this.required_truck_bodies = ["curtainsider", "standard-tent"];
                break;
            }
            case "12t": {
                this.vehicleSize = "solo";
                this.required_truck_bodies = ["curtainsider", "standard-tent"];
                break;
            }
            case "40t": {
                this.vehicleSize = "lorry";
                this.required_truck_bodies = ["curtainsider", "standard-tent"];
                break;
            }
            default: {
                break;
            }
        }
    }

    otherRequirements = [] as string[];
    generateOtherRequirements() {
        if (this.supervisor.isADR) {
            this.otherRequirements.push("7_adr");
        }
        if (this.supervisor.tailLift) {
            this.otherRequirements.push("3_lift");
        }
    }

    prepareTransEuFreightFromSupervisor() {
        const firstPlace = this.supervisor.places[0];
        const lastPlace = this.supervisor.places[this.supervisor.places.length - 1];
        const placeLoadingWeight = this.convertFirstPlaceWeight(firstPlace, "t");
        this.generateRequiredVehicleSizeAndTruckBodiesFromVehicleType();
        this.generateOtherRequirements();

        this.transEuFreight = {
            publish: true,
            external_source: "",
            capacity: placeLoadingWeight,
            payment: {
                price: {
                    period: {
                        days: 60,
                        payment: "deferred",
                    }
                }
            },
            requirements: {
                is_ftl: true,
                vehicle_size: this.vehicleSize,
                required_truck_bodies: this.required_truck_bodies,
                other_requirements: this.otherRequirements
            },
            loads: [],
            spots: [
                {
                    spot_order: 1,
                    place: {
                        address: {
                            country: firstPlace.country,
                            postal_code: firstPlace.postalCode,
                            locality: firstPlace.city
                        }
                    },
                    operations: [
                        {
                            operation_order: 1,
                            type: "loading",
                            timespans: {
                                beginAsString: firstPlace.time,
                                endAsAsString: firstPlace.time
                            }
                        }
                    ]
                },
                {
                    spot_order: 2,
                    place: {
                        address: {
                            country: lastPlace.country,
                            postal_code: lastPlace.postalCode,
                            locality: lastPlace.city
                        }
                    },
                    operations: [
                        {
                            operation_order: 1,
                            type: "unloading",
                            timespans: {
                                beginAsString: lastPlace.time,
                                endAsAsString: lastPlace.time
                            }
                        }
                    ]
                }
            ]
        } as TransEuFreightRestModel;
    }

    prepareCourierNetFreightFromSupervisor() {
        const firstPlace = this.supervisor.places[0];
        const lastPlace = this.supervisor.places[this.supervisor.places.length - 1];
        const placeLoadingWeight = this.convertFirstPlaceWeight(firstPlace, "kg");

        this.courierNetFreight = {
            reference: this.supervisor.id.toString(),
            startCountryCode: firstPlace.country,
            startZip: firstPlace.postalCode,
            startCity: firstPlace.city,
            destinationCountryCode: lastPlace.country,
            destinationZip: lastPlace.postalCode,
            destinationCity: lastPlace.city,
            loadingBeginDate: firstPlace.time,
            deliveryBeginDate: lastPlace.time,
            packages: [
                {
                    weight: placeLoadingWeight,
                } as CourierNetPackageRestModel,
            ]
        } as CourierNetFreightRestModel;
    }

    async sendFreightToFreightExchanges() {
        this.prepareTransEuFreightFromSupervisor();
        this.prepareCourierNetFreightFromSupervisor();

        await this.$store.direct.dispatch.freightExchange
            .openSendFreightToFreightExchangesDialog({
                showDialog: true
            })
            .then(async confirm => {
                if (confirm) {
                    this.$store.direct.commit.freightExchange.setTransEuFreight({} as TransEuFreightRestModel);
                }
            });
    }

    async deleteSuperviorAttachment(item: SupervisorAttachmentRestModel) {
        await this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: this.$t("supervisor.deleteSupervisorAttachmentTitle").toString(),
                message: this.$t("supervisor.deleteSupervisorAttachmentMessage").toString() + ": " + item.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.deleteSuperviorAttachment({ fileId: item.id });
                    await this.$store.direct.dispatch.supervisor.loadSupervisorAttachmentList();
                }
            })
    }

    async createNewSupervisor() {
        const newTab = window.open('', '_blank');
        if (newTab) {
            try {
                const selectedPlacesIdcsArray = this.selectedPlacesIdcsForCreatingNewSupervisor.join(',');
                const createSupervisorUrl = `${window.location.origin}/supervisor/create?oldSupervisorId=${this.supervisor.id}&selectedPlacesIdcsArray=${selectedPlacesIdcsArray}`;
                newTab.location.href = createSupervisorUrl;
            } catch (error) {
                console.error('Failed to create supervisor:', error);
                newTab.close();
            }
        } else {
            console.error('Failed to open a new tab.');
        }
    }

    async getHandlingUnitDetails(index: number) {
        const handlingUnitId = this.currentSupervisorPlaces[index].handlingUnitId;
        if (handlingUnitId != undefined) {
            const model = await this.$store.direct.dispatch.supervisorEncory.getHandlingUnitDetails({ handlingUnitId: this.currentSupervisorPlaces[index].handlingUnitId as number }) as SupervisorEncoryHandlingUnitForSupervisorPlaceModel;

            this.currentSupervisorPlaces[index].handlingUnitId = undefined;
            this.currentSupervisorPlaces[index].name = model.dealerAddressName;
            this.currentSupervisorPlaces[index].street = model.dealerAddressStreet;
            this.currentSupervisorPlaces[index].postalCode = model.dealerAddressPostalCode;
            this.currentSupervisorPlaces[index].city = model.dealerAddressCity;
            this.currentSupervisorPlaces[index].country = "DE";

            this.supervisorPlacePostalCodeOrCountryChanged(this.currentSupervisorPlaces[index], index);
        }
    }

    fillEncoryUnloadingPlaceDetails(index: number) {
        this.currentSupervisorPlaces[index].name = "RLP Encory";
        this.currentSupervisorPlaces[index].street = "Lise-Meitner-Str. 2";
        this.currentSupervisorPlaces[index].postalCode = "41415";
        this.currentSupervisorPlaces[index].city = "Grevenbroich";
        this.currentSupervisorPlaces[index].country = "DE";

        this.currentSupervisorPlaces[index].isLocationConfirmedByUser = true;
        this.currentSupervisorPlaces[index].lat = 51.08461088294029;
        this.currentSupervisorPlaces[index].lng = 6.623177279490337;

        this.$forceUpdate();
    }

    showSendingSmsDialog(phoneNumber: string) {
        this.sendingSmsDialogPhoneNumber = phoneNumber.replace(/\s/g, "");;
        this.$store.direct.dispatch.supervisor.openSendingSmsDialog()
    }

    async calculateContractorSenderFreightBasedOnSupervisorProject(calculatingByAccounting: boolean) {
        await this.generateCountrySelectors();

        const model =
            {
                calculatingByAccounting: calculatingByAccounting,
                startCountryCode: this.currentSupervisorPlaces[0].country,
                endCountryCode: this.currentSupervisorPlaces[this.currentSupervisorPlaces.length - 1].country,
                numberOfKilometers: this.supervisor.numberOfKilometers,
                vehicleType: this.supervisor.vehicleType,
                contractorSenderId: this.selectedContractorSender.value,
                kpiRepackaging: this.supervisor.kpiRepackaging,
                kpiRepackagingMultiplicator: this.supervisor.kpiRepackagingMultiplicator,
                kpiDocumentGeneration: this.supervisor.kpiDocumentGeneration,
                kpiDocumentGenerationMultiplicator: this.supervisor.kpiDocumentGenerationMultiplicator,
                kpiFerryBoats: this.supervisor.kpiFerryBoats,
                kpiFerryBoatsMultiplicator: this.supervisor.kpiFerryBoatsMultiplicator,
                kpiFerryBoatsCountries: this.supervisor.kpiFerryBoatsCountries,
                isCustomKpiFerryBoats: this.supervisor.isCustomKpiFerryBoats,
                customKpiFerryBoatsPrice: this.supervisor.customKpiFerryBoatsPrice,
                kpiCustomDuties: this.supervisor.kpiCustomDuties,
                kpiCustomDutiesMultiplicator: this.supervisor.kpiCustomDutiesMultiplicator,
                kpiLifts: this.supervisor.kpiLifts,
                kpiLiftsMultiplicator: this.supervisor.kpiLiftsMultiplicator,
                kpiStornos: this.supervisor.kpiStornos,
                kpiStornosMultiplicator: this.supervisor.kpiStornosMultiplicator,
                kpiAdditionalLoadingPoint: this.supervisor.kpiAdditionalLoadingPoint,
                kpiAdditionalLoadingPointMultiplicator: this.supervisor.kpiAdditionalLoadingPointMultiplicator
            } as SupervisorProjectFreightCalculationRestModel;

        const result = await this.$store.direct.dispatch.supervisor.calculateContractorSenderFreightBasedOnSupervisorProject({ model: model }) as any;

        if (calculatingByAccounting) {
            this.supervisor.freightCalculationForAccounting = result.calculationDetails;
            this.supervisor.hasSaleValueBeenRecalculatedByAccounting = true;
            this.supervisor.saleValueBeforeRecalculationByAccounting = this.supervisor.saleValue;
            this.supervisor.saleValue = result.contractorSenderFreightPrice.toFixed(2);
        }
        else {
            this.supervisor.freightCalculation = result.calculationDetails;
            this.supervisor.hasSaleValueBeenRecalculatedByAccounting = false;
            this.supervisor.freightCalculationForAccounting = null;
        }
        this.needRecalculatingFreight = false;
    }

    async generateCountrySelectors() {
        this.changeNeedRecalculatingFreigntValue();

        if (!this.supervisor.kpiFerryBoats) {
            this.supervisor.kpiFerryBoatsCountries = undefined as any;
            return;
        }
        else if (this.supervisor.kpiFerryBoats && this.supervisor.kpiFerryBoatsMultiplicator == undefined) {
            this.supervisor.kpiFerryBoatsMultiplicator = 1;
        }

        const multiplicator = Number(this.supervisor.kpiFerryBoatsMultiplicator);
        let kpiFerryBoatsCountriesLength = 0;

        if (this.supervisor.kpiFerryBoatsCountries == null) {
            this.supervisor.kpiFerryBoatsCountries = [];

        }
        else {
            kpiFerryBoatsCountriesLength = this.supervisor.kpiFerryBoatsCountries.length;
        }
        if (multiplicator > kpiFerryBoatsCountriesLength) {
            const itemsToAdd = multiplicator - kpiFerryBoatsCountriesLength;
            this.supervisor.kpiFerryBoatsCountries.push(...Array(itemsToAdd).fill(null));
        }
        else if (multiplicator < kpiFerryBoatsCountriesLength) {
            this.supervisor.kpiFerryBoatsCountries.splice(multiplicator);
        }
    }

    isCustomKpiFerryBoatsChanged() {
        this.changeNeedRecalculatingFreigntValue();

        if (!this.supervisor.isCustomKpiFerryBoats) {
            this.supervisor.customKpiFerryBoatsPrice = undefined;
        }
    }

    kpiFerryBoatsChanged() {
        this.kpiChanged("kpiFerryBoats")
        this.generateCountrySelectors();
    }

    kpiChanged(kpiType: string) {
        this.changeNeedRecalculatingFreigntValue();

        if (kpiType == "kpiRepackaging" && this.supervisor.kpiRepackaging == null) {
            this.supervisor.kpiRepackaging = undefined;
            this.supervisor.kpiRepackagingMultiplicator = undefined;
        }
        if (kpiType == "kpiDocumentGeneration" && this.supervisor.kpiDocumentGeneration == null) {
            this.supervisor.kpiDocumentGeneration = undefined;
            this.supervisor.kpiDocumentGenerationMultiplicator = undefined;
        }
        if (kpiType == "kpiCustomDuties" && this.supervisor.kpiCustomDuties == null) {
            this.supervisor.kpiCustomDuties = undefined;
            this.supervisor.kpiCustomDutiesMultiplicator = undefined;
        }
        if (kpiType == "kpiFerryBoats" && this.supervisor.kpiFerryBoats == null) {
            this.supervisor.kpiFerryBoats = undefined;
            this.supervisor.kpiFerryBoatsMultiplicator = undefined;
        }
        if (kpiType == "kpiLifts" && this.supervisor.kpiLifts == null) {
            this.supervisor.kpiLifts = undefined;
            this.supervisor.kpiLiftsMultiplicator = undefined;
        }
        if (kpiType == "kpiStornos" && this.supervisor.kpiStornos == null) {
            this.supervisor.kpiStornos = undefined;
            this.supervisor.kpiStornosMultiplicator = undefined;
        }
        if (kpiType == "kpiAdditionalLoadingPoint" && this.supervisor.kpiAdditionalLoadingPoint == null) {
            this.supervisor.kpiAdditionalLoadingPoint = undefined;
            this.supervisor.kpiAdditionalLoadingPointMultiplicator = undefined;
        }
    }

    changeNeedRecalculatingFreigntValue() {
        this.needRecalculatingFreight = true;
    }

    async confirmTransporeonTransportOrder() {
        const result = await this.$store.direct.dispatch.transporeon.confirmTransporeonTransportOrder({ supervisorId: this.supervisor.id});

    }

    destroyed() {
        this.clearSupervisorLockedForEditingUntilValue();
        this.$store.direct.commit.supervisor.setSupervisor({} as SupervisorRestModel);
        this.contractorSenderDetailsForSupervisor = {} as ContractorDetailsForSupervisorRestModel;
        this.contractorCarrierDetailsForSupervisor = {} as ContractorDetailsForSupervisorRestModel;
        this.$store.direct.commit.supervisor.setValidationError({} as any);
        this.$store.direct.commit.supervisor.setIsDroppedFromPreliminaryList(false);
        this.isSupervisorInHistoricalState = false;
        this.isSupervisorCopied = false;
        this.isFullDuplicating = false;
        this.$store.direct.commit.freightExchange.setTransEuFreight({} as TransEuFreightRestModel);
        this.contractorCarrierEmailList = [] as ListItemNumberModel[];
    }

    backToList() {
        if (this.backToSupervisorArchiveListForSettlement) {
            this.$router.push({ path: "/supervisor/archive-list-for-settlement" });
        }
        else if (this.backToSupervisorAllOrdersList) {
            this.$router.push({ name: "Supervisor all orders list" });
        }
        else if (this.supervisor.isArchived) {
            this.$router.push({ name: "Supervisor archive list" });
        }
        else {
            this.$router.push({ path: "/supervisor/list" });
        }
        this.backToSupervisorArchiveListForSettlement = false;
        this.isSupervisorCopied = false;
    }
}
