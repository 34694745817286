import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";
import ListItemNumberModel from "../../models/listItemNumberModel";
import PlaceStateRestModel from "../../models/rest/tracking/placeStateRestModel";
import SupervisorTrackingRestModel from "../../models/rest/tracking/supervisorTrackingRestModel";
import TrackingRestModel from "../../models/rest/tracking/trackingRestModel";
import { axiosInstance } from "../../plugins/axios";


export interface TransporeonModuleStateInterface {
    supervisorTrackingVehicleList: SupervisorTrackingRestModel[];
    supervisorTrackingVehicleListLoading: boolean;
    trackingVehicleData: TrackingRestModel;
    trackingSupervisorData: TrackingRestModel;
    placeStateList: PlaceStateRestModel[];
    placeStateListLoading: false | boolean;
    placeStateListTotalCount: number;
    selectedContractor: ListItemNumberModel;
    placeStateListPagerOptions: any;
}

const trackingModule = defineModule({
    namespaced: true,
    state: {
    } as TransporeonModuleStateInterface,
    mutations: { 
    },
    actions: {
        confirmTransporeonTransportOrder(context, payload: { supervisorId: number}) {
            const { commit, state, rootState, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/transporeon/confirm-transporeon-transport-order/" + payload.supervisorId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        if (resp.data.isSuccess) {
                            rootState.supervisor.supervisor.isTransporeonTransportOrderConfirmed = true;

                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Potwierdzenie zlecenia transporeon",
                                message: "Zlecenie transportowe zostało potwierdzone.",
                                links: [],
                                options: { buttonColor: "primary", layoutColor: "primary" },
                                buttonType: "ok"
                            });
                        }
                        else {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Potwierdzenie zlecenia transporeon",
                                message: "BŁĄD: " + resp.data.errorMessage,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                        }
                        return resp.data
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        }
    }
});

export default trackingModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, trackingModule)