import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import draggable from 'vuedraggable';
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import { ContractorTypes } from "../../models/emums/contractorTypes";
import { Roles } from "../../models/emums/roles";
import { SupervisorAssignedTypeEnum } from "../../models/emums/supervisor/supervisorAssignedTypeEnum";
import { SupervisorPlaceTypeEnum } from "../../models/emums/supervisor/supervisorPlaceTypeEnum";
import ListItemNumberModel from "../../models/listItemNumberModel";
import LocalStorageApplicationStateDataModel from "../../models/localStorageApplicationStateDataModel";
import ContractorDetailsForSupervisorRestModel from "../../models/rest/contractor/contractorDetailsForSupervisorRestModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import SupervisorCheckpointRestModel from "../../models/rest/supervisor/supervisorCheckpointRestModel";
import SupervisorItemRestModel from "../../models/rest/supervisor/supervisorItemRestModel";
import SupervisorListRequestModel from "../../models/rest/supervisor/supervisorListRequestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";
import DraggableTableRowHandler from "../custom/draggable-table-row-handler.vue";
import CheckPlacesDialog from "./dialog/check-places-dialog.vue";
import FlagIcon from "../custom/flag-icon.vue"

@Component({
    components: {
        "flag-icon": FlagIcon,
        "confirm-dialog": ConfirmDialog,
        "check-places-dialog": CheckPlacesDialog,
        "draggable": draggable,
        "draggable-table-row-handler": DraggableTableRowHandler,
    }
})

export default class SupervisorList extends Vue {
    showPreliminaryTab = true;
    showLoadingUnloadingTab = true;
    showCheckpointsTab = false;
    reloadSupervisorListDataInterval: number | undefined = 0;
    initialIntervalCounter: number = 60;
    intervalCounter: number = this.initialIntervalCounter;
    contractorSenderSearch = "";
    contractorPayerSearch = "";
    contractorCarrierSearch = "";
    searchCustomerIdcs = [] as number[];
    searchExcludedCustomerIdcs = [] as number[];
    searchPayerIdcs = [] as number[];
    searchShippingCompanyIdcs = [] as number[];
    showOnlyUnloadedSupervisorList = false;
    realLoadOutDateStartDateLabel = this.$t("supervisor.realLoadOutDateStartDate");
    realLoadOutDateEndDateLabel = this.$t("supervisor.realLoadOutDateEndDate");
    searchRealLoadOutDateStartDate = "";
    searchRealLoadOutDateEndDate = "";

    supervisorListHeaders = [
        {
            text: this.$t("supervisor.additionalColumn"),
            align: "center",
            value: "additionalColumn",
            sortable: false,
            width: "1%",
            visible: this.localStorageApplicationStateData.supervisorAdditionalInfoColumn
        },
        {
            text: this.$t("supervisor.identificationData"),
            align: "start",
            value: "identificationData",
            sortable: false,
            visible: true
        },
        {
            text: "",
            align: "start",
            value: "loadingCountry",
            sortable: true,
            visible: this.localStorageApplicationStateData.supervisorFlagColumns
        },
        {
            text: this.$t("supervisor.loadingData"),
            align: "start",
            value: "loadingData",
            sortable: true,
            visible: true
        },
        {
            text: "",
            align: "start",
            value: "unloadingCountry",
            sortable: true,
            visible: this.localStorageApplicationStateData.supervisorFlagColumns
        },
        {
            text: this.$t("supervisor.unloadingData"),
            align: "start",
            value: "unloadingData",
            sortable: true,
            visible: true
        },
        {
            text: this.$t("supervisor.customer"),
            align: "start",
            value: "customer",
            sortable: false,
            visible: this.localStorageApplicationStateData.supervisorShowLoadingUnloadingDetails
        },
        {
            text: this.$t("supervisor.shippingCompany"),
            align: "start",
            value: "shippingCompany",
            sortable: false,
            visible: this.localStorageApplicationStateData.supervisorShowLoadingUnloadingDetails

        },
        {
            text: this.$t("supervisor.actions"),
            align: "right",
            value: "actions",
            sortable: false,
            visible: true
        }
    ];

    supervisorCheckpointsListHeaders = [
        {
            text: this.$t("supervisor.identificationData"),
            align: "start",
            value: "identificationData",
            sortable: false
        },
        {
            text: this.$t("supervisor.loadingData"),
            align: "start",
            value: "loadingData",
            sortable: false
        },
        {
            text: this.$t("supervisor.unloadingData"),
            align: "start",
            value: "unloadingData",
            sortable: false
        },
        {
            text: this.$t("supervisor.nearestCheckpoint"),
            align: "start",
            value: "nearestCheckpoint",
            sortable: false
        },
        {
            text: this.$t("supervisor.actions"),
            align: "right",
            value: "actions",
            sortable: false
        }
    ];

    get localStorageApplicationStateData(): LocalStorageApplicationStateDataModel {
        return this.$store.direct.state.common.localStorageApplicationStateData;
    }

    @Watch("localStorageApplicationStateData", { deep: true })
    localStorageApplicationStateDataHandles() {
        this.$store.direct.dispatch.common.updateLocalStorageApplicationStateData();

        this.supervisorListHeaders.forEach((x: any) => {
            if (x.value == "additionalColumn") {
                x.visible = this.localStorageApplicationStateData.supervisorAdditionalInfoColumn;
            }
            if (x.value == "loadingCountry") {
                x.visible = this.localStorageApplicationStateData.supervisorFlagColumns;
            }
            if (x.value == "unloadingCountry") {
                x.visible = this.localStorageApplicationStateData.supervisorFlagColumns;
            }
            if (x.value == "customer") {
                x.visible = this.localStorageApplicationStateData.supervisorShowLoadingUnloadingDetails;
            }
            if (x.value == "shippingCompany") {
                x.visible = this.localStorageApplicationStateData.supervisorShowLoadingUnloadingDetails;
            }
        })
    }

    get supervisorAssignedTypes() {
        const types = new Map<string, number>();
        types.set(Object.keys(SupervisorAssignedTypeEnum)[Object.values(SupervisorAssignedTypeEnum).indexOf(SupervisorAssignedTypeEnum.All as unknown as SupervisorAssignedTypeEnum)], SupervisorAssignedTypeEnum.All)
        types.set(Object.keys(SupervisorAssignedTypeEnum)[Object.values(SupervisorAssignedTypeEnum).indexOf(SupervisorAssignedTypeEnum.Assigned as unknown as SupervisorAssignedTypeEnum)], SupervisorAssignedTypeEnum.All)
        types.set(Object.keys(SupervisorAssignedTypeEnum)[Object.values(SupervisorAssignedTypeEnum).indexOf(SupervisorAssignedTypeEnum.Unassigned as unknown as SupervisorAssignedTypeEnum)], SupervisorAssignedTypeEnum.All)
        return types;
    }

    get assignedPreliminarySupervisorsType(): number {
        return this.$store.direct.state.supervisor.assignedPreliminarySupervisorsType;
    }

    set assignedPreliminarySupervisorsType(val: number) {
        this.$store.direct.commit.supervisor.setAssignedPreliminarySupervisorsType(val);
    }

    get activeSupervisorsPlacesType(): number {
        return this.$store.direct.state.supervisor.activeSupervisorsPlacesType;
    }

    set activeSupervisorsPlacesType(val: number) {
        this.$store.direct.commit.supervisor.setActiveSupervisorsPlacesType(val);
    }

    get computedHeaders(): any[] {
        return this.supervisorListHeaders.filter(x => x.visible == true);
    }

    get searchText(): string {
        return this.$store.direct.state.supervisor.supervisorListSearchText;
    }

    set searchText(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorListSearchText(value);
    }

    get searchPlacePostalCode(): string {
        return this.$store.direct.state.supervisor.supervisorListSearchPlacePostalCode;
    }

    set searchPlacePostalCode(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorListSearchPlacePostalCode(value);
    }

    get searchPhoneNumber(): string {
        return this.$store.direct.state.supervisor.supervisorListSearchPhoneNumber;
    }

    set searchPhoneNumber(value: string) {
        this.$store.direct.commit.supervisor.setSupervisorListSearchPhoneNumber(value);
    }

    get supervisorListPagerOptions(): any {
        return this.$store.direct.state.supervisor.supervisorListPagerOptions;
    }

    set supervisorListPagerOptions(value: any) {
        this.$store.direct.commit.supervisor.setSupervisorListPagerOptions(value);
    }

    get supervisorExcludedCustomersListPagerOptions(): any {
        return this.$store.direct.state.supervisor.supervisorExcludedCustomersListPagerOptions;
    }

    set supervisorExcludedCustomersListPagerOptions(value: any) {
        this.$store.direct.commit.supervisor.setSupervisorExcludedCustomersListPagerOptions(value);
    }

    get checkpointListPagerOptions(): any {
        return this.$store.direct.state.supervisor.supervisorCheckpointsListPagerOptions;
    }

    set checkpointListPagerOptions(value: any) {
        this.$store.direct.commit.supervisor.setSupervisorCheckpointsListPagerOptions(value);
    }

    get departments(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.contractorAssets.departments;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get supervisorListLoading(): boolean {
        return this.$store.direct.state.supervisor.supervisorListLoading;
    }

    get supervisorList(): SupervisorItemRestModel[] {
        return this.$store.direct.state.supervisor.supervisorList;
    }

    get supervisorListTotalCount(): number {
        return this.$store.direct.state.supervisor.supervisorListTotalCount;
    }

    get supervisorExcludedCustomersList(): SupervisorItemRestModel[] {
        return this.$store.direct.state.supervisor.supervisorExcludedCustomersList;
    }

    get supervisorExcludedCustomersListTotalCount(): number {
        return this.$store.direct.state.supervisor.supervisorExcludedCustomersListTotalCount;
    }

    get supervisorCheckpointList(): SupervisorCheckpointRestModel[] {
        return this.$store.direct.state.supervisor.supervisorCheckpointList;
    }

    get supervisorCheckpointListTotalCount(): number {
        return this.$store.direct.state.supervisor.supervisorCheckpointListTotalCount;
    }

    get preliminarySupervisorList(): SupervisorRestModel[] {
        return this.$store.direct.state.supervisor.preliminarySupervisorList;
    }

    get preliminarySupervisorListLoading(): boolean {
        return this.$store.direct.state.supervisor.preliminarySupervisorListLoading;
    }

    get preliminarySupervisorListTotalCount(): number {
        return this.$store.direct.state.supervisor.preliminarySupervisorListTotalCount;
    }

    get preliminarySupervisorListPagerOptions(): any {
        return this.$store.direct.state.supervisor.preliminarySupervisorListPagerOptions;
    }

    set preliminarySupervisorListPagerOptions(value: any) {
        this.$store.direct.commit.supervisor.setPreliminarySupervisorListPagerOptions(value);
    }

    get contractorSenderDetailsForSupervisor(): ContractorDetailsForSupervisorRestModel {
        return this.$store.direct.state.contractor.contractorSenderDetailsForSupervisor;
    }

    get supervisor(): SupervisorRestModel {
        return this.$store.direct.state.supervisor.supervisor;
    }

    get supervisorValidationError(): any {
        return this.$store.direct.state.supervisor.validationError;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get userIsAdmin(): boolean {
        return this.currentUserRoles.includes(Roles.Admin) ? true : false;
    }

    get userIsAdminDisponentAdminOrDisponent(): boolean {
        return (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.DisponentAdmin) || this.currentUserRoles.includes(Roles.Disponent)) ? true : false;
    }

    get showContractorPayerSearchingField(): boolean {
        return (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin) || this.currentUserRoles.includes(Roles.Accountant)) ? true : false;
    }

    get selectedContractorSenderListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.selectedContractorSenderListForFilter;
    }

    set selectedContractorSenderListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisor.setSelectedContractorSenderListForFilter(val);
    }

    get selectedContractorPayerListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.selectedContractorPayerListForFilter;
    }

    set selectedContractorPayerListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisor.setSelectedContractorPayerListForFilter(val);
    }

    get selectedContractorCarrierListForFilter(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.selectedContractorCarrierListForFilter;
    }

    set selectedContractorCarrierListForFilter(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.supervisor.setSelectedContractorCarrierListForFilter(val);
    }

    get contractorSenderFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorSenderFound;
    }

    get contractorPayerFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorPayerFound;
    }

    get contractorCarrierFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.supervisor.contractorCarrierFound;
    }

    @Watch("supervisorExcludedCustomersListPagerOptions", { deep: true })
    async supervisorExcludedCustomersListPagerOptionsHandles() {
        await this.loadSupervisorList();
    }

    @Watch("checkpointListPagerOptions", { deep: true })
    async checkpointListPagerOptionsHandles() {
        await this.loadSupervisorList();
    }

    @Watch("supervisorListPagerOptions", { deep: true })
    async supervisorListPagerOptionsHandles() {
        await this.loadSupervisorList();
    }

    @Watch("contractorSenderSearch")
    async contractorSenderSearchLoadItems(val: string) {
        if (val != null) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Client], contractorPropertiesEnabled: [], source: "supervisor" });
        }

        if (this.selectedContractorSenderListForFilter.length > 0) {
            this.selectedContractorSenderListForFilter.forEach((x: any) => {
                if (!this.contractorSenderFound.includes(x)) {
                    this.contractorSenderFound.push(x);
                }
            })
        }
    }

    @Watch("contractorPayerSearch")
    async contractorPayerSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Payer], contractorPropertiesEnabled: [], source: "supervisor" });
        if (this.selectedContractorPayerListForFilter.length > 0) {
            this.selectedContractorPayerListForFilter.forEach((x: any) => {
                if (!this.contractorPayerFound.includes(x)) {
                    this.contractorPayerFound.push(x);
                }
            })
        }
    }

    @Watch("contractorCarrierSearch")
    async contractorCarrierSearchLoadItems(val: string) {
        if (val != null) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Carrier], contractorPropertiesEnabled: [], source: "supervisor" });
        }

        if (this.selectedContractorCarrierListForFilter.length > 0) {
            this.selectedContractorCarrierListForFilter.forEach((x: any) => {
                if (!this.contractorCarrierFound.includes(x)) {
                    this.contractorCarrierFound.push(x);
                }
            })
        }
    }

    @Watch("preliminarySupervisorListPagerOptions", { deep: true })
    async preliminarySupervisorListPagerOptionsHandles() {
        await this.loadPreliminarySupervisorList();
    }

    @Watch("localStorageApplicationStateData.supervisorExcludedCustomersLoadingUnloadingTab", { deep: true })
    async supervisorExcludedCustomersLoadingUnloadingTabHandles() {
        this.$store.direct.dispatch.common.updateLocalStorageApplicationStateData();
        await this.loadSupervisorList();
    }

    getSupervisorItemStyle(item: SupervisorRestModel) {
        const overdueLoadingTextColor = "red--text ";
        const customClearanceTextColor = "blue--text ";
        const overdueUnloadingTextColor = "teal--text text--accent-4 ";

        let style = "";
        if (item.nextCheckAtTimeTo != null) {
            if (dayjs(item.nextCheckAt) <= dayjs().add(-30, 'minute')) {
                if (item.nextCheckType == SupervisorPlaceTypeEnum.LoadIn) {
                    style += overdueLoadingTextColor;
                }
                else if (item.nextCheckType == SupervisorPlaceTypeEnum.CustomClearance) {
                    style += customClearanceTextColor;
                }
                else {
                    style += overdueUnloadingTextColor;
                }
            }
        }
        else if (dayjs(item.nextCheckAt) <= dayjs()) {
            if (item.nextCheckType == SupervisorPlaceTypeEnum.LoadIn) {
                style += overdueLoadingTextColor;
            }
            else if (item.nextCheckType == SupervisorPlaceTypeEnum.CustomClearance) {
                style += customClearanceTextColor;
            }
            else {
                style += overdueUnloadingTextColor;
            }
        }

        if (item.nextCheckType == SupervisorPlaceTypeEnum.Done || item.nextCheckType == SupervisorPlaceTypeEnum.Storno) {
            style += "text-decoration-line-through";
        }
        return style;
    }

    getSupervisorItemIconStyle(item: SupervisorRestModel) {
        const overdueLoadingTextColor = "red ";
        const customClearanceTextColor = "blue ";
        const overdueUnloadingTextColor = "#00BFA5 ";

        let style = "";
        if (item.nextCheckAtTimeTo != null) {
            if (dayjs(item.nextCheckAt) <= dayjs().add(-30, 'minute')) {
                if (item.nextCheckType == SupervisorPlaceTypeEnum.LoadIn) {
                    style += overdueLoadingTextColor;
                }
                else if (item.nextCheckType == SupervisorPlaceTypeEnum.CustomClearance) {
                    style += customClearanceTextColor;
                }
                else {
                    style += overdueUnloadingTextColor;
                }
            }
        }
        else if (dayjs(item.nextCheckAt) <= dayjs()) {
            if (item.nextCheckType == SupervisorPlaceTypeEnum.LoadIn) {
                style += overdueLoadingTextColor;
            }
            else if (item.nextCheckType == SupervisorPlaceTypeEnum.CustomClearance) {
                style += customClearanceTextColor;
            }
            else {
                style += overdueUnloadingTextColor;
            }
        }
        else {
            style += "grey ";
        }

        return style;
    }

    getSupervisorCheckpointItemStyle(item: SupervisorCheckpointRestModel) {
        if (dayjs(item.checkTime) <= dayjs() && item.isChecked == false) {
            return "red--text";
        }
        return "";
    }

    getSupervisorVehicleTypeStringStyle(item: SupervisorRestModel) {
        const overdueLoadingTextColor = "red--text ";
        const customClearanceTextColor = "blue--text ";
        const overdueUnloadingTextColor = "teal--text text--accent-4 ";

        let style = "";
        if (item.nextCheckAtTimeTo != null) {
            if (dayjs(item.nextCheckAt) <= dayjs().add(-30, 'minute')) {
                if (item.nextCheckType == SupervisorPlaceTypeEnum.LoadIn) {
                    style += overdueLoadingTextColor;
                }
                else if (item.nextCheckType == SupervisorPlaceTypeEnum.CustomClearance) {
                    style += customClearanceTextColor;
                }
                else {
                    style += overdueUnloadingTextColor;
                }
            }
        }
        else if (dayjs(item.nextCheckAt) <= dayjs()) {
            if (item.nextCheckType == SupervisorPlaceTypeEnum.LoadIn) {
                style += overdueLoadingTextColor;
            }
            else if (item.nextCheckType == SupervisorPlaceTypeEnum.CustomClearance) {
                style += customClearanceTextColor;
            }
            else {
                style += overdueUnloadingTextColor;
            }
        }
        else {
            style += "grey--text ";
        }
        style += "font-weight-bold text-uppercase";
        return style;
    }

    changeVehicleStringLutonVanToLuton(vehicleType: string) {
        return vehicleType == "luton van" ? "LUTON" : vehicleType;
    }

    formatDateTime(time: any) {
        if (time != null) {
            return dayjs(time).format("YYYY-MM-DD HH:mm");
        }
    }

    async created() {
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.supervisor.loadSupervisorAssets();
        this.$store.direct.dispatch.common.loadVehicleTypeList({ source: "supervisor" });


        await this.loadPreliminarySupervisorList();
        this.reloadSupervisorListDataInterval = setInterval(async () => {
            await this.reloadSupervisorListData();
        }, 1000);
    }

    destroyed() {
        clearInterval(this.reloadSupervisorListDataInterval);
        this.reloadSupervisorListDataInterval = undefined;
    }

    async reloadSupervisorListData() {
        --this.intervalCounter;
        if (this.intervalCounter < 1) {
            this.resetIntervalCounter();
            await this.loadPreliminarySupervisorList();
            await this.loadSupervisorList();
        }
    }

    resetIntervalCounter() {
        this.intervalCounter = this.initialIntervalCounter;
    }

    editSupervisorClick(supervisorId: number, isSupervisorInHistoricalState: boolean) {
        if (isSupervisorInHistoricalState) {
            return "/supervisor/edit/" + supervisorId + "/" + "history";
        }
        return "/supervisor/edit/" + supervisorId;
    }

    showSupervisorAttachmentClick(supervisorId: number) {
        return "/supervisor/attachment-list/" + supervisorId;
    }

    async archiveSupervisor(item: SupervisorRestModel, isPreliminary = false) {
        this.resetIntervalCounter();
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.archive")}: "${item.orderId}"`,
                message: `${this.$t("supervisor.archiveMessage")}`,
                links: [],
                options: { buttonColor: "primary", layoutColor: "primary" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.changeSupervisorArchiveValue({ supervisorId: item.id, isArchived: true });
                    if (isPreliminary) {
                        await this.loadPreliminarySupervisorList();
                    }
                    else {
                        await this.loadSupervisorList();
                    }
                }
                this.resetIntervalCounter();
            });
    }

    showSupervisorPlaceForCheckDialog(item: SupervisorRestModel) {
        this.$store.direct.commit.supervisor.setShowSupervisorPlaceForCheckDialog(true);
        this.$store.direct.commit.supervisor.setIsSupervisorCustomChecked({ supervisorId: item.id, isCustomChecked: item.isCustomChecked });
        this.$store.direct.dispatch.supervisor.loadSupervisorPlaceForCheckList(item.id);
    }

    async showOnlyUnloadedSupervisorListClick() {
        this.resetIntervalCounter();
        await this.loadSupervisorList();
        this.resetIntervalCounter();
    }

    async showTransporeonTransportOrdersClick() {
        this.resetIntervalCounter();
        await this.loadPreliminarySupervisorList();
        this.resetIntervalCounter();
    }

    async checkIfContractorHasTrackingEnabled(item: SupervisorRestModel) {
        await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: item.id });

        if (this.supervisor.isEditedByUserName != null) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("supervisor.orderIsCurrentlyBeingUsed")}`,
                    message: `${this.$t("supervisor.orderIsCurrentlyBeingUsedBy")}` + this.supervisor.isEditedByUserName,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });
        }
        else {
            if (item.customerCompanyId != null && item.customerCompanyId != undefined) {
                await this.$store.direct.dispatch.contractor.getContractorDetailsForSupervisor({ contractorId: item.customerCompanyId, contractorType: ContractorTypes.Client })

                if (this.contractorSenderDetailsForSupervisor.trackingEnabled == true) {
                    this.showSupervisorPlaceForCheckDialog(item);
                }
                else {
                    await this.$store.direct.dispatch.supervisor.changeSupervisorCustomCheck({ supervisorId: item.id, isChecked: !item.isCustomChecked });
                    await this.loadSupervisorList()
                }
            }
            else {
                await this.$store.direct.dispatch.supervisor.changeSupervisorCustomCheck({ supervisorId: item.id, isChecked: !item.isCustomChecked });
                await this.loadSupervisorList()
            }
        }
    }

    async searchClick() {
        this.resetIntervalCounter();
        await this.loadSupervisorList();
        await this.loadPreliminarySupervisorList();
    }

    async clearSearchTextClick() {
        this.resetIntervalCounter();
        this.searchText = '';
        await this.loadSupervisorList();
        await this.loadPreliminarySupervisorList();
    }

    async clearSearchPlacePostalCodeClick() {
        this.resetIntervalCounter();
        this.searchPlacePostalCode = '';
        await this.loadSupervisorList();
        await this.loadPreliminarySupervisorList();
    }

    async clearSearchPhoneNumberClick() {
        this.resetIntervalCounter();
        this.searchPhoneNumber = '';
        await this.loadSupervisorList();
        await this.loadPreliminarySupervisorList();
    }

    async clearSearchContractorSenderClick() {
        this.selectedContractorSenderListForFilter = [] as ContractorSearchItemModel[];
        await this.loadSupervisorList();
        await this.loadPreliminarySupervisorList();
    }

    async clearSearchContractoPayerClick() {
        this.selectedContractorPayerListForFilter = [] as ContractorSearchItemModel[];
        await this.loadSupervisorList();
        await this.loadPreliminarySupervisorList();
    }

    async clearSearchContractorCarrierClick() {
        this.selectedContractorCarrierListForFilter = [] as ContractorSearchItemModel[];
        await this.loadSupervisorList();
        await this.loadPreliminarySupervisorList();
    }

    async assignedPreliminarySupervisorsTypeClick() {
        this.resetIntervalCounter();
        await this.loadPreliminarySupervisorList();
    }

    async activeSupervisorsPlacesTypeClick() {
        this.resetIntervalCounter();
        await this.loadSupervisorList();
    }

    createSupervisor() {
        this.setIsSupervisorPreliminary(false);
        this.$router.push({ path: "/supervisor/create" });
    }

    async loadSupervisorList() {
        if (this.selectedContractorSenderListForFilter != undefined && this.selectedContractorSenderListForFilter.length > 0) {
            this.searchCustomerIdcs = this.selectedContractorSenderListForFilter.map(x => x.value);
        }
        else {
            this.searchCustomerIdcs = [];
        }
        if (this.selectedContractorPayerListForFilter != undefined && this.selectedContractorPayerListForFilter.length > 0) {
            this.searchPayerIdcs = this.selectedContractorPayerListForFilter.map(x => x.value);
        }
        else {
            this.searchPayerIdcs = [];
        }
        if (this.selectedContractorCarrierListForFilter != undefined && this.selectedContractorCarrierListForFilter.length > 0) {
            this.searchShippingCompanyIdcs = this.selectedContractorCarrierListForFilter.map(x => x.value);
        }
        else {
            this.searchShippingCompanyIdcs = [];
        }

        await this.$store.direct.dispatch.supervisor.loadSupervisorList({
            requestModel: await this.prepareSupervisorListRequestModel()
        });
    }

    createPreliminarySupervisor() {
        this.setIsSupervisorPreliminary(true);
        this.$router.push({ path: "/supervisor/create" });
    }

    async setIsSupervisorPreliminary(isPreliminary: boolean) {
        await this.$store.direct.commit.supervisor.setIsSupervisorPreliminary(isPreliminary);
    }

    async loadPreliminarySupervisorList() {
        await this.$store.direct.dispatch.supervisor.loadPreliminarySupervisorList({
            requestModel: await this.prepareSupervisorPreliminaryListRequestModel()
        });
    }

    async prepareSupervisorListRequestModel() {
        return {
            ... await this.prepareCommonRequestElements(),
            supervisorListPage: this.supervisorListPagerOptions.page,
            supervisorListPageSize: this.supervisorListPagerOptions.itemsPerPage,
            sortBy: this.supervisorListPagerOptions.sortBy != undefined ? this.supervisorListPagerOptions.sortBy[0] : null,
            sortDesc: this.supervisorListPagerOptions.sortDesc != undefined ? this.supervisorListPagerOptions.sortDesc[0] : false,
            searchRealLoadOutDateStartDate: this.searchRealLoadOutDateStartDate,
            searchRealLoadOutDateEndDate: this.searchRealLoadOutDateEndDate,
            supervisorExcludedCustomersListPage: this.supervisorExcludedCustomersListPagerOptions.page,
            supervisorExcludedCustomersListPageSize: this.supervisorExcludedCustomersListPagerOptions.itemsPerPage,
        } as SupervisorListRequestModel;
    }

    async prepareSupervisorPreliminaryListRequestModel() {
        return {
            ...await this.prepareCommonRequestElements(),
            preliminarySupervisorListPage: this.preliminarySupervisorListPagerOptions.page,
            preliminarySupervisorListPageSize: this.preliminarySupervisorListPagerOptions.itemsPerPage,
            sortBy: this.preliminarySupervisorListPagerOptions.sortBy != undefined ? this.preliminarySupervisorListPagerOptions.sortBy[0] : null,
            sortDesc: this.preliminarySupervisorListPagerOptions.sortDesc != undefined ? this.preliminarySupervisorListPagerOptions.sortDesc[0] : false,
            assignedPreliminarySupervisorsType: this.assignedPreliminarySupervisorsType,
        } as SupervisorListRequestModel;
    }

    async prepareCommonRequestElements() {
        return {
            checkpointListPage: this.checkpointListPagerOptions.page,
            checkpointListPageSize: this.checkpointListPagerOptions.itemsPerPage,
            searchText: this.searchText,
            searchPlacePostalCode: this.searchPlacePostalCode,
            searchPhoneNumber: this.searchPhoneNumber,
            departments: await this.localStorageApplicationStateData.supervisorListSelectedDepartments,
            activeSupervisorsPlacesType: this.activeSupervisorsPlacesType,
            searchCustomerIdcs: this.searchCustomerIdcs,
            searchPayerIdcs: this.searchPayerIdcs,
            searchShippingCompanyIdcs: this.searchShippingCompanyIdcs,
            showOnlyUnloadedSupervisorList: this.showOnlyUnloadedSupervisorList,
            showTransporeonTransportOrders: await this.localStorageApplicationStateData.showTransporeonTransportOrders,
            showSupervisorExcludedCustomersLoadingUnloadingTab: await this.localStorageApplicationStateData.supervisorExcludedCustomersLoadingUnloadingTab
        };
    }

    async addSupervisorFromPreliminary(event: any) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.transferPreliminaryDialogTitle")}`,
                message: `${this.$t("supervisor.transferPreliminaryDialogMessage")}`,
                links: [],
                options: { buttonColor: "primary", layoutColor: "primary" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    if (event?.added?.element?.id) {
                        await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: event.added.element.id });
                        this.supervisor.isPreliminary = false;

                        if (this.supervisor.isEditedByUserName == null) {
                            await this.$store.direct.dispatch.supervisor.saveSupervisor({ addingSupervisorFromPreliminary: true });
                        }

                        if (Object.keys(this.supervisorValidationError).length || this.supervisor.isEditedByUserName != null) {
                            this.$store.direct.commit.supervisor.setIsDroppedFromPreliminaryList(true);
                            await this.$router.push({ path: "/supervisor/edit/" + event.added.element.id });
                        }
                        else {
                            await this.loadSupervisorList();
                            await this.loadPreliminarySupervisorList();
                        }
                    }
                }
                else {
                    await this.loadSupervisorList();
                    await this.loadPreliminarySupervisorList();
                }
            });
    }

    async assignUserToSupervisor(item: SupervisorRestModel) {
        await this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.assignSupervisor")}: "${item.orderId}"`,
                message: `${this.$t("supervisor.assignSupervisorMessage")}`,
                links: [],
                options: { buttonColor: "primary", layoutColor: "primary" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.assignUserToSupervisor({ supervisorId: item.id, isAssigned: true });
                    await this.loadPreliminarySupervisorList();
                }
            });
    }

    async unassignUserToSupervisor(item: SupervisorRestModel) {
        await this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("supervisor.unassignSupervisor")}: "${item.orderId}"`,
                message: `${this.$t("supervisor.unassignSupervisorMessage")}`,
                links: [],
                options: { buttonColor: "error", layoutColor: "error" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.assignUserToSupervisor({ supervisorId: item.id, isAssigned: false });
                    await this.loadPreliminarySupervisorList();
                }
            });
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchRealLoadOutDateStartDateValue") {
            this.searchRealLoadOutDateStartDate = "";
        }
        if (clearableValue == "searchRealLoadOutDateEndDateValue") {
            this.searchRealLoadOutDateEndDate = "";
        }
    }

    async duplicateSupervisorClick(supervisor: SupervisorRestModel) {
        await this.$router.push({ path: "/supervisor/duplicate/" + supervisor.id + "/false" });
    }

    transEuAuth() {
        this.$store.direct.dispatch.freightExchange.getTransEuAuthUrl();
    }
}
