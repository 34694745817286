import Vue from "vue";
import { Component } from "vue-property-decorator";
import { BranchEnum } from "../../../models/emums/branchEnum";
import ContractorAssetsRestModel from "../../../models/rest/contractor/contractorAssetsRestModel";
import ContractorDetailsForSettlementRestModel from "../../../models/rest/contractor/contractorDetailsForSettlementRestModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CostTypeRestModel from "../../../models/rest/register/costTypeRestModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";
import DepartmentItemRestModel from "../../../models/rest/register/departmentItemRestModel";
import SettlementCostDocumentItemRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentItemRestModel";
import SettlementCostDocumentRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";
import SettlementIncomeDocumentItemRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentItemRestModel";
import SettlementIncomeDocumentRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SettlementAssetRestModel from "../../../models/rest/settlement/settlementAssetRestModel";
import SupervisorForSettlementRestModel from "../../../models/rest/supervisor/supervisorForSettlementRestModel";
import SupervisorPlaceRestModel from "../../../models/rest/supervisor/supervisorPlaceRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";

@Component
export default class SettlementDialog extends Vue {

    supervisorCostDocumentListHeaders = [
        {
            text: "Id",
            align: "start",
            value: "id",
            sortable: false,
        },
        {
            text: this.$t("costDocument.documentNumber"),
            align: "start",
            value: "documentNumber",
            sortable: false,
        },
        {
            text: this.$t("costDocument.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    supervisorIncomeDocumentListHeaders = [
        {
            text: "Id",
            align: "start",
            value: "id",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.documentNumber"),
            align: "start",
            value: "documentNumber",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.supervisor.showSupervisorSettlementDialog;
    }

    get supervisorCostDocumentList(): SettlementCostDocumentRestModel[] {
        return this.$store.direct.state.settlementCostDocument.supervisorCostDocumentList;
    }

    set supervisorCostDocumentList(val: SettlementCostDocumentRestModel[]) {
        this.$store.direct.commit.settlementCostDocument.setSupervisorCostDocumentList(val);
    }

    get supervisorCostDocumentListLoading(): boolean {
        return this.$store.direct.state.settlementCostDocument.supervisorCostDocumentListLoading;
    }

    get supervisorIncomeDocumentList(): SettlementIncomeDocumentRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.supervisorIncomeDocumentList;
    }

    set supervisorIncomeDocumentList(val: SettlementIncomeDocumentRestModel[]) {
        this.$store.direct.commit.settlementIncomeDocument.setSupervisorIncomeDocumentList(val);
    }

    get supervisorIncomeDocumentListLoading(): boolean {
        return this.$store.direct.state.settlementIncomeDocument.supervisorIncomeDocumentListLoading;
    }

    get supervisor(): SupervisorRestModel {
        return this.$store.direct.state.supervisor.supervisor;
    }

    get supervisorList(): SupervisorForSettlementRestModel[] {
        return this.$store.direct.state.settlementCommon.supervisorList;
    }

    set supervisorList(val: SupervisorForSettlementRestModel[]) {
        this.$store.direct.commit.settlementCommon.setSupervisorList(val);
    }

    get costDocument() {
        return this.$store.direct.state.settlementCostDocument.costDocument;
    }

    set costDocument(val: SettlementCostDocumentRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocument(val);
    }

    get incomeDocument() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocument;
    }

    set incomeDocument(val: SettlementIncomeDocumentRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocument(val);
    }

    get contractorDetailsForSettlement(): ContractorDetailsForSettlementRestModel {
        return this.$store.direct.state.contractor.contractorDetailsForSettlement;
    }

    get contractorAssets(): ContractorAssetsRestModel {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get settlementAssets(): SettlementAssetRestModel {
        return this.$store.direct.state.settlementCommon.settlementAssets;
    }

    get costDocumentSaleDate() {
        return this.$store.direct.state.settlementCostDocument.costDocumentSaleDate;
    }

    set costDocumentSaleDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentSaleDate(val);
    }

    get incomeDocumentSaleDate() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentSaleDate;
    }

    set incomeDocumentSaleDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentSaleDate(val);
    }

    created() {
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.supervisor.loadSupervisorAssets();
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
    }

    async createSupervisorCostDocumentClick() {
        await this.$router.push({ path: "/settlement/cost-document/create" });

        this.supervisorList.push({ id: this.supervisor.id, orderId: this.supervisor.orderId, accountingComment: this.supervisor.accountingComment } as SupervisorRestModel);

        await this.fillCostDocumentContractor();

        this.costDocument.documentCountry = this.contractorDetailsForSettlement.country;
        this.costDocument.paymentTerm = this.supervisor.paymentTermPurchase;
        this.costDocument.currencyCode = this.supervisor.purchaseValueCurrency;

        if (this.contractorDetailsForSettlement?.country != undefined) {
            this.$store.direct.commit.register.setCountry({ code: this.costDocument.documentCountry } as CountryRestModel);
            this.$store.direct.dispatch.common.loadCountryTaxRateList();
        }

        const foundDepartment = this.contractorAssets.departments.find((x: DepartmentItemRestModel) => {
            return x.value == this.supervisor.locationId;
        });
        if (foundDepartment != undefined) {
            this.costDocument.branchId = foundDepartment.branchId;
        }

        if (this.supervisor.isStorno) {
            this.costDocumentSaleDate = (this.supervisor.stornoDate != null && this.supervisor.stornoDate != undefined) ? this.supervisor.stornoDate : this.supervisor.createdAt;
        }
        else {
            const filteredPlaces = this.supervisor.places.filter((x: SupervisorPlaceRestModel) => !x.isDeleted);
            if (filteredPlaces.length != 0) {
                this.costDocumentSaleDate = filteredPlaces.sort((firstPlace: SupervisorPlaceRestModel, secondPlace: SupervisorPlaceRestModel) => {
                    return +new Date(secondPlace.realTime) - +new Date(firstPlace.realTime);
                })[0].realTime;
            }
            else {
                this.costDocumentSaleDate = this.supervisor.createdAt;
            }
        }

        const carrierFreightCostType = this.settlementAssets.costTypes.find((x: CostTypeRestModel) => {
            return x.id == 9; // Carrier's freight
        })

        const costDocumentItems = [] as SettlementCostDocumentItemRestModel[];
        const costDocumentItem = {
            costType: { ...carrierFreightCostType } as CostTypeRestModel,
            descriptionDetailed: "",
            price: this.supervisor.purchaseValue,
            taxRate: undefined as any,
            quantity: 1,
            unit: "SZT.",
        } as SettlementCostDocumentItemRestModel;
        costDocumentItems.push(costDocumentItem);
        this.costDocument.costDocumentItems = costDocumentItems;
        this.$store.direct.commit.settlementCostDocument.setCostDocumentItemList(costDocumentItems);

        this.$store.direct.commit.settlementCostDocument.setCostDocumentBankAccountList([]);

        this.$root.$emit('loadContractorBankAccountListAndAssignCostDocumentBankAccounts');

        this.cancelClick();
    }

    async fillCostDocumentContractor() {
        if (this.supervisor.shippingCompanyId != null) {
            await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: this.supervisor.shippingCompanyId });

            this.costDocument.contractor = await { text: this.contractorDetailsForSettlement.name, value: this.contractorDetailsForSettlement.id } as ContractorSearchItemModel;
            this.costDocument.contractorName = await this.contractorDetailsForSettlement.name;
            this.costDocument.contractorSymbol = await this.contractorDetailsForSettlement.symbol;
            this.costDocument.contractorCountry = await this.contractorDetailsForSettlement.country;
            this.costDocument.contractorCity = await this.contractorDetailsForSettlement.city;
            this.costDocument.contractorPostalCode = await this.contractorDetailsForSettlement.postalCode;
            this.costDocument.contractorStreetAndNumber = await this.contractorDetailsForSettlement.streetAndNumber;
            this.costDocument.contractorTin = await this.contractorDetailsForSettlement.tin;
            this.costDocument.contractorTinPrefix = await this.contractorDetailsForSettlement.tinPrefix;
        }
    }

    editSupervisorCostDocument(item: SettlementCostDocumentRestModel) {
        if (item.isDocumentCorrection) {
            return "/settlement/cost-document/correction/edit/" + item.id;
        }
        else {
            return "/settlement/cost-document/edit/" + item.id;
        }
    }

    async createSupervisorIncomeDocumentClick() {
        const result = await this.$store.direct.dispatch.supervisorForSettlement.checkIfAllSupervisorHaveSaleValueRecalculatedByAccounting({ supervisorIdcs: [this.supervisor.id] });
        if (!result) {
            return;
        }

        await this.$router.push({ path: "/settlement/income-document/create" });

        this.supervisorList.push({ id: this.supervisor.id, orderId: this.supervisor.orderId, accountingComment: this.supervisor.accountingComment } as SupervisorRestModel);

        this.incomeDocument.incomeDocumentInvoiceTypeId = 1;
        this.incomeDocument.paymentTerm = this.supervisor.paymentTermSale;
        this.incomeDocument.currencyCode = this.supervisor.saleValueCurrency;

        const placesArray = [] as string[];
        this.supervisor.places.forEach((x: SupervisorPlaceRestModel) => {
            placesArray.push(x.city + " (" + x.country + ") ");
        });

        this.incomeDocument.supervisorRoute = placesArray.join(" - ")

        await this.fillIncomeDocumentContractor();

        const foundDepartment = this.contractorAssets.departments.find((x: DepartmentItemRestModel) => {
            return x.value == this.supervisor.locationId;
        });
        if (foundDepartment != undefined) {
            this.incomeDocument.branchId = foundDepartment.branchId;

            if (foundDepartment.branchId == BranchEnum.DE) {
                this.incomeDocument.documentLanguage = "DE";
            }
            else {
                this.incomeDocument.documentLanguage = "PL";
            }
        }

        if (this.supervisor.isStorno) {
            this.incomeDocumentSaleDate = (this.supervisor.stornoDate != null && this.supervisor.stornoDate != undefined) ? this.supervisor.stornoDate : this.supervisor.createdAt;
        }
        else {
            const filteredPlaces = this.supervisor.places.filter((x: SupervisorPlaceRestModel) => !x.isDeleted);
            if (filteredPlaces.length != 0) {
                this.incomeDocumentSaleDate = filteredPlaces.sort((firstPlace: SupervisorPlaceRestModel, secondPlace: SupervisorPlaceRestModel) => {
                    return +new Date(secondPlace.realTime) - +new Date(firstPlace.realTime);
                })[0].realTime;
            }
            else {
                this.incomeDocumentSaleDate = this.supervisor.createdAt;
            }
        }

        const incomeDocumentItems = [] as SettlementIncomeDocumentItemRestModel[];
        const incomeDocumentItem = {
            description: "",
            descriptionDetailed: "",
            price: this.supervisor.saleValue,
            taxRate: 0,
            quantity: 1,
            unit: "SZT.",
        } as SettlementIncomeDocumentItemRestModel;

        incomeDocumentItems.push(incomeDocumentItem);
        this.incomeDocument.incomeDocumentItems = incomeDocumentItems;
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemList(incomeDocumentItems);

        this.cancelClick();
    }

    async fillIncomeDocumentContractor() {
        if (!this.supervisor.isPayerRequired) {
            if (this.supervisor.customerCompanyId != null) {
                await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: this.supervisor.customerCompanyId });

                this.incomeDocument.contractor = { text: this.contractorDetailsForSettlement.name, value: this.contractorDetailsForSettlement.id } as ContractorSearchItemModel;

                this.incomeDocument.contractorName = this.contractorDetailsForSettlement.name;
                this.incomeDocument.contractorSymbol = this.contractorDetailsForSettlement.symbol;
                this.incomeDocument.contractorCountry = this.contractorDetailsForSettlement.country;
                this.incomeDocument.contractorCity = this.contractorDetailsForSettlement.city;
                this.incomeDocument.contractorPostalCode = this.contractorDetailsForSettlement.postalCode;
                this.incomeDocument.contractorStreetAndNumber = this.contractorDetailsForSettlement.streetAndNumber;
                this.incomeDocument.contractorTin = this.contractorDetailsForSettlement.tin;
                this.incomeDocument.contractorTinPrefix = this.contractorDetailsForSettlement.tinPrefix;
            }
        }
        else {
            if (this.supervisor.payerId != null) {
                await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: this.supervisor.payerId });

                this.incomeDocument.contractor = { text: this.contractorDetailsForSettlement.name, value: this.contractorDetailsForSettlement.id } as ContractorSearchItemModel;

                this.incomeDocument.contractorName = this.contractorDetailsForSettlement.name;
                this.incomeDocument.contractorSymbol = this.contractorDetailsForSettlement.symbol;
                this.incomeDocument.contractorCountry = this.contractorDetailsForSettlement.country;
                this.incomeDocument.contractorCity = this.contractorDetailsForSettlement.city;
                this.incomeDocument.contractorPostalCode = this.contractorDetailsForSettlement.postalCode;
                this.incomeDocument.contractorStreetAndNumber = this.contractorDetailsForSettlement.streetAndNumber;
                this.incomeDocument.contractorTin = this.contractorDetailsForSettlement.tin;
                this.incomeDocument.contractorTinPrefix = this.contractorDetailsForSettlement.tinPrefix;
            }
        }
    }

    editSupervisorIncomeDocument(item: SettlementIncomeDocumentRestModel) {
        if (item.isDocumentCorrection) {
            return "/settlement/income-document/correction/edit/" + item.id;
        }
        else {
            return "/settlement/income-document/edit/" + item.id;
        }
    }

    cancelClick() {
        this.$store.direct.dispatch.supervisor.isNotConfirmedSupervisorSettlementDialog();
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.supervisor.isConfirmedSupervisorSettlementDialog();
        }
    }
}
