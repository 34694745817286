import { saveFile } from "@/assets/file-saver";
import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import SupervisorAmountSummaryRestModel from "../../models/rest/supervisor/supervisorAmountSummaryRestModel";
import SupervisorArchiveForSettlementListRequestModel from "../../models/rest/supervisor/supervisorArchiveListForSettlementRequestModel";
import SupervisorArchiveListItemForSettlementRestModel from "../../models/rest/supervisor/supervisorArchiveListItemForSettlementRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SupervisorForSettlementModuleStateInterface {
    supervisorArchiveListLoading: boolean;
    supervisorArchiveList: SupervisorArchiveListItemForSettlementRestModel[],
    supervisorArchiveListTotalCount: number,
    supervisorArchiveListAmountSummaryList: SupervisorAmountSummaryRestModel[],
    supervisorArchiveListSearchLoadInPlace: string,
    supervisorArchiveListSearchLoadOutPlace: string,
    supervisorArchiveListTwoWayPlaceSearching: boolean;
    supervisorArchiveListShowSupervisorsAlsoWithoutPurchaseAndSaleValue: boolean;
    supervisorArchiveListSearchPlaceType: number,
    supervisorArchiveListSearchUnloadingPlaceType: number,
    supervisorArchiveListSearchPlaceName: string,
    supervisorArchiveListSearchUnloadingPlaceName: string,
    supervisorArchiveListSearchPlaceCountry: string,
    supervisorArchiveListSearchUnloadingPlaceCountry: string,
    supervisorArchiveListSearchPlacePostalCode: string,
    supervisorArchiveListSearchUnloadingPlacePostalCode: string,
    supervisorArchiveListSearchPlaceCity: string,
    supervisorArchiveListSearchUnloadingPlaceCity: string,
    supervisorArchiveListSearchPlaceStreet: string,
    supervisorArchiveListSearchUnloadingPlaceStreet: string,
    supervisorArchiveListSearchCustomerIdcs: number[],
    supervisorArchiveListSearchPayerIdcs: number[],
    supervisorArchiveListSearchShippingCompanyIdcs: number[],
    supervisorArchiveListSearchRegistrationNumber: string,
    supervisorArchiveListSearchOrderId: string,
    supervisorArchiveListSearchCustomerOrderId: string,
    supervisorArchiveListSearchVehicleTypeList: string[],
    supervisorArchiveListSearchRDriver1Name: string,
    supervisorArchiveListSearchDriver2Name: string,
    supervisorArchiveListSearchComment: string,
    supervisorArchiveListSearchIsADR: boolean,
    supervisorArchiveListSearchTaillift: boolean,
    supervisorArchiveListSearchExtinguisher: boolean,
    supervisorArchiveListSearchHasCmrFile: boolean,
    supervisorArchiveListSearchHasCostDocument: number,
    supervisorArchiveListSearchHasIncomeDocument: number,
    supervisorArchiveListSearchCreatedAtStartDate: string,
    supervisorArchiveListSearchCreatedAtEndDate: string,
    supervisorArchiveListSearchRealLoadInDateStartDate: string,
    supervisorArchiveListSearchRealLoadInDateEndDate: string,
    supervisorArchiveListSearchRealLoadOutDateStartDate: string,
    supervisorArchiveListSearchRealLoadOutDateEndDate: string,
    supervisorArchiveListSearchPlanningDateStartDate: string,
    supervisorArchiveListSearchUnloadingPlanningDateStartDate: string,
    supervisorArchiveListSearchPlanningDateEndDate: string,
    supervisorArchiveListSearchUnloadingPlanningDateEndDate: string,
    supervisorArchiveListSearchRealDateStartDate: string,
    supervisorArchiveListSearchUnloadingRealDateStartDate: string,
    supervisorArchiveListSearchRealDateEndDate: string,
    supervisorArchiveListSearchUnloadingRealDateEndDate: string,
    supervisorArchiveListSearchBranchId: number,
    supervisorArchiveListSearchIsStorno: number,
    supervisorArchiveListFilterAsActive: boolean,
    supervisorArchiveListPagerOptions: any,
    selectedContractorSenderListForFilter: ContractorSearchItemModel[];
    selectedContractorPayerListForFilter: ContractorSearchItemModel[];
    selectedContractorCarrierListForFilter: ContractorSearchItemModel[];
    contractorSenderFound: ContractorSearchItemModel[];
    contractorPayerFound: ContractorSearchItemModel[];
    contractorCarrierFound: ContractorSearchItemModel[];
    supervisorCmrAttachmentData: string | undefined;
    backToSupervisorArchiveListForSettlement: boolean;
}

const supervisorForSettlementModule = defineModule({
    namespaced: true,
    state: {
        supervisorArchiveListLoading: false,
        supervisorArchiveList: [] as SupervisorArchiveListItemForSettlementRestModel[],
        supervisorArchiveListTotalCount: 0,
        supervisorArchiveListAmountSummaryList: [] as SupervisorAmountSummaryRestModel[],
        supervisorArchiveListSearchLoadInPlace: "",
        supervisorArchiveListSearchLoadOutPlace: "",
        supervisorArchiveListTwoWayPlaceSearching: false,
        supervisorArchiveListShowSupervisorsAlsoWithoutPurchaseAndSaleValue: false,
        supervisorArchiveListSearchPlaceType: 0,
        supervisorArchiveListSearchUnloadingPlaceType: 0,
        supervisorArchiveListSearchPlaceName: "",
        supervisorArchiveListSearchUnloadingPlaceName: "",
        supervisorArchiveListSearchPlaceCountry: "",
        supervisorArchiveListSearchUnloadingPlaceCountry: "",
        supervisorArchiveListSearchPlacePostalCode: "",
        supervisorArchiveListSearchUnloadingPlacePostalCode: "",
        supervisorArchiveListSearchPlaceCity: "",
        supervisorArchiveListSearchUnloadingPlaceCity: "",
        supervisorArchiveListSearchPlaceStreet: "",
        supervisorArchiveListSearchUnloadingPlaceStreet: "",
        supervisorArchiveListSearchCustomerIdcs: [] as number[],
        supervisorArchiveListSearchPayerIdcs: [] as number[],
        supervisorArchiveListSearchShippingCompanyIdcs: [] as number[],
        supervisorArchiveListSearchRegistrationNumber: "",
        supervisorArchiveListSearchOrderId: "",
        supervisorArchiveListSearchCustomerOrderId: "",
        supervisorArchiveListSearchVehicleTypeList: [] as string[],
        supervisorArchiveListSearchRDriver1Name: "",
        supervisorArchiveListSearchDriver2Name: "",
        supervisorArchiveListSearchComment: "",
        supervisorArchiveListSearchIsADR: false,
        supervisorArchiveListSearchTaillift: false,
        supervisorArchiveListSearchExtinguisher: false,
        supervisorArchiveListSearchHasCmrFile: false,
        supervisorArchiveListSearchHasCostDocument: 0,
        supervisorArchiveListSearchHasIncomeDocument: 0,
        supervisorArchiveListSearchCreatedAtStartDate: "",
        supervisorArchiveListSearchCreatedAtEndDate: "",
        supervisorArchiveListSearchRealLoadInDateStartDate: "",
        supervisorArchiveListSearchRealLoadInDateEndDate: "",
        supervisorArchiveListSearchRealLoadOutDateStartDate: "",
        supervisorArchiveListSearchRealLoadOutDateEndDate: "",
        supervisorArchiveListSearchPlanningDateStartDate: "",
        supervisorArchiveListSearchUnloadingPlanningDateStartDate: "",
        supervisorArchiveListSearchPlanningDateEndDate: "",
        supervisorArchiveListSearchUnloadingPlanningDateEndDate: "",
        supervisorArchiveListSearchRealDateStartDate: "",
        supervisorArchiveListSearchUnloadingRealDateStartDate: "",
        supervisorArchiveListSearchRealDateEndDate: "",
        supervisorArchiveListSearchUnloadingRealDateEndDate: "",
        supervisorArchiveListSearchBranchId: 0,
        supervisorArchiveListSearchIsStorno: 0,
        supervisorArchiveListFilterAsActive: false,
        supervisorArchiveListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: [""],
            sortDesc: [false]
        },
        selectedContractorSenderListForFilter: [] as ContractorSearchItemModel[],
        selectedContractorPayerListForFilter: [] as ContractorSearchItemModel[],
        selectedContractorCarrierListForFilter: [] as ContractorSearchItemModel[],
        contractorSenderFound: [] as ContractorSearchItemModel[],
        contractorPayerFound: [] as ContractorSearchItemModel[],
        contractorCarrierFound: [] as ContractorSearchItemModel[],
        supervisorCmrAttachmentData: undefined,
        backToSupervisorArchiveListForSettlement: false,
    } as SupervisorForSettlementModuleStateInterface,
    mutations: {
        setSupervisorArchiveListLoading(state, value: boolean) {
            state.supervisorArchiveListLoading = value;
        },
        setSupervisorArchiveList(state, value: SupervisorArchiveListItemForSettlementRestModel[]) {
            state.supervisorArchiveList = value;
        },
        setSuperVisorArchiveListTotalCount(state, value: number) {
            state.supervisorArchiveListTotalCount = value;
            if ((state.supervisorArchiveListPagerOptions.page * state.supervisorArchiveListPagerOptions.itemsPerPage) >= (value + state.supervisorArchiveListPagerOptions.itemsPerPage)) {
                state.supervisorArchiveListPagerOptions.page = 1;
            }
        },
        setSupervisorArchiveListAmountSummaryList(state, value: SupervisorAmountSummaryRestModel[]) {
            state.supervisorArchiveListAmountSummaryList = value;
        },
        setSupervisorArchiveListSearchLoadInPlace(state, value: string) {
            state.supervisorArchiveListSearchLoadInPlace = value;
        },
        setSupervisorArchiveListSearchLoadOutPlace(state, value: string) {
            state.supervisorArchiveListSearchLoadOutPlace = value;
        },
        setSupervisorArchiveListTwoWayPlaceSearching(state, value: boolean) {
            state.supervisorArchiveListTwoWayPlaceSearching = value;
        },
        setSupervisorArchiveListShowSupervisorsAlsoWithoutPurchaseAndSaleValue(state, value: boolean) {
            state.supervisorArchiveListShowSupervisorsAlsoWithoutPurchaseAndSaleValue = value;
        },
        setSupervisorArchiveListSearchPlaceType(state, value: number) {
            state.supervisorArchiveListSearchPlaceType = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceType(state, value: number) {
            state.supervisorArchiveListSearchUnloadingPlaceType = value;
        },
        setSupervisorArchiveListSearchPlaceName(state, value: string) {
            state.supervisorArchiveListSearchPlaceName = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceName(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlaceName = value;
        },
        setSupervisorArchiveListSearchPlaceCountry(state, value: string) {
            state.supervisorArchiveListSearchPlaceCountry = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceCountry(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlaceCountry = value;
        },
        setSupervisorArchiveListSearchPlacePostalCode(state, value: string) {
            state.supervisorArchiveListSearchPlacePostalCode = value;
        },
        setSupervisorArchiveListSearchUnloadingPlacePostalCode(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlacePostalCode = value;
        },
        setSupervisorArchiveListSearchPlaceCity(state, value: string) {
            state.supervisorArchiveListSearchPlaceCity = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceCity(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlaceCity = value;
        },
        setSupervisorArchiveListSearchPlaceStreet(state, value: string) {
            state.supervisorArchiveListSearchPlaceStreet = value;
        },
        setSupervisorArchiveListSearchUnloadingPlaceStreet(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlaceStreet = value;
        },
        setSupervisorArchiveListSearchCustomerIdcs(state, value: number[]) {
            state.supervisorArchiveListSearchCustomerIdcs = value;
        },
        setSupervisorArchiveListSearchPayerIdcs(state, value: number[]) {
            state.supervisorArchiveListSearchPayerIdcs = value;
        },
        setSupervisorArchiveListSearchShippingCompanyIdcs(state, value: number[]) {
            state.supervisorArchiveListSearchShippingCompanyIdcs = value;
        },
        setSupervisorArchiveListSearchRegistrationNumber(state, value: string) {
            state.supervisorArchiveListSearchRegistrationNumber = value;
        },
        setSupervisorArchiveListSearchOrderId(state, value: string) {
            state.supervisorArchiveListSearchOrderId = value;
        },
        setSupervisorArchiveListSearchCustomerOrderId(state, value: string) {
            state.supervisorArchiveListSearchCustomerOrderId = value;
        },
        setSupervisorArchiveListSearchVehicleTypeList(state, value: string[]) {
            state.supervisorArchiveListSearchVehicleTypeList = value;
        },
        setSupervisorArchiveListSearchDriver1Name(state, value: string) {
            state.supervisorArchiveListSearchRDriver1Name = value;
        },
        setSupervisorArchiveListSearchDriver2Name(state, value: string) {
            state.supervisorArchiveListSearchDriver2Name = value;
        },
        setSupervisorArchiveListSearchComment(state, value: string) {
            state.supervisorArchiveListSearchComment = value;
        },
        setSupervisorArchiveListSearchIsADR(state, value: boolean) {
            state.supervisorArchiveListSearchIsADR = value;
        },
        setSupervisorArchiveListSearchTaillift(state, value: boolean) {
            state.supervisorArchiveListSearchTaillift = value;
        },
        setSupervisorArchiveListSearchExtinguisher(state, value: boolean) {
            state.supervisorArchiveListSearchExtinguisher = value;
        },
        setSupervisorArchiveListSearchHasCmrFile(state, value: boolean) {
            state.supervisorArchiveListSearchHasCmrFile = value;
        },
        setSupervisorArchiveListSearchHasCostDocument(state, value: number) {
            state.supervisorArchiveListSearchHasCostDocument = value;
        },
        setSupervisorArchiveListSearchHasIncomeDocument(state, value: number) {
            state.supervisorArchiveListSearchHasIncomeDocument = value;
        },
        setSupervisorArchiveListSearchPlanningDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchPlanningDateStartDate = value;
        },
        setSupervisorArchiveListSearchCreatedAtStartDate(state, value: string) {
            state.supervisorArchiveListSearchCreatedAtStartDate = value;
        },
        setSupervisorArchiveListSearchCreatedAtEndDate(state, value: string) {
            state.supervisorArchiveListSearchCreatedAtEndDate = value;
        },
        setSupervisorArchiveListSearchRealLoadInDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchRealLoadInDateStartDate = value;
        },
        setSupervisorArchiveListSearchRealLoadInDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchRealLoadInDateEndDate = value;
        },
        setSupervisorArchiveListSearchRealLoadOutDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchRealLoadOutDateStartDate = value;
        },
        setSupervisorArchiveListSearchRealLoadOutDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchRealLoadOutDateEndDate = value;
        },
        setSupervisorArchiveListSearchUnloadingPlanningDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlanningDateStartDate = value;
        },
        setSupervisorArchiveListSearchPlanningDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchPlanningDateEndDate = value;
        },
        setSupervisorArchiveListSearchUnloadingPlanningDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchUnloadingPlanningDateEndDate = value;
        },
        setSupervisorArchiveListSearchRealDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchRealDateStartDate = value;
        },
        setSupervisorArchiveListSearchUnloadingRealDateStartDate(state, value: string) {
            state.supervisorArchiveListSearchUnloadingRealDateStartDate = value;
        },
        setSupervisorArchiveListSearchRealDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchRealDateEndDate = value;
        },
        setSupervisorArchiveListSearchUnloadingRealDateEndDate(state, value: string) {
            state.supervisorArchiveListSearchUnloadingRealDateEndDate = value;
        },
        setSupervisorArchiveListSearchBranchId(state, value: number) {
            state.supervisorArchiveListSearchBranchId = value;
        },
        setSupervisorArchiveListSearchIsStorno(state, value: number) {
            state.supervisorArchiveListSearchIsStorno = value;
        },
        setSupervisorArchiveListFilterAsActive(state, value: boolean) {
            state.supervisorArchiveListFilterAsActive = value;
        },
        setSupervisorArchiveListPagerOptions(state, value) {
            state.supervisorArchiveListPagerOptions = value;
        },
        setSelectedContractorSenderListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedContractorSenderListForFilter = value;
        },
        setSelectedContractorPayerListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedContractorPayerListForFilter = value;
        },
        setSelectedContractorCarrierListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedContractorCarrierListForFilter = value;
        },
        setContractorSenderFound(state, value: ContractorSearchItemModel[]) {
            state.contractorSenderFound = value;
        },
        setContractorPayerFound(state, value: ContractorSearchItemModel[]) {
            state.contractorPayerFound = value;
        },
        setContractorCarrierFound(state, value: ContractorSearchItemModel[]) {
            state.contractorCarrierFound = value;
        },
        setSupervisorCmrAttachmentData(state, value: string | undefined) {
            state.supervisorCmrAttachmentData = value;
        },
        setBackToSupervisorArchiveListForSettlement(state, value: boolean) {
            state.backToSupervisorArchiveListForSettlement = value;
        },
    },
    actions: {
        loadSupervisorArchiveListForSettlement(context, payload: { requestModel: SupervisorArchiveForSettlementListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorArchiveListLoading(true);
                axiosInstance
                    .post("/api/supervisor/archive-list-for-settlement", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorArchiveList(resp.data.items);
                        commit.setSuperVisorArchiveListTotalCount(resp.data.totalItemsCount)
                        commit.setSupervisorArchiveListAmountSummaryList(resp.data.amountSummaryList)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorArchiveListLoading(false);
                    })
            })
        },
        async isWithoutInvoicingChanged(context, payload: { supervisorId: number, isChecked: boolean }) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/is-without-invoicing-changed/" + payload.supervisorId + "/" + payload.isChecked)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async isServiceCompletedChanged(context, payload: { supervisorId: number, isChecked: boolean }) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/is-service-completed-changed/" + payload.supervisorId + "/" + payload.isChecked)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        downloadXlsReportSupervisorArchiveListForSettlement(context, payload: { requestModel: SupervisorArchiveForSettlementListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setSupervisorArchiveListLoading(true);
                axiosInstance
                    .post("/api/supervisor/export-archive-list-for-settlement/", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorArchiveListLoading(false);
                    })
            });
        },
        getSupervisorCmrAttachmentData(context, payload: { supervisorId: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisor/get-cmr-attachment-data/" + payload.supervisorId)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorCmrAttachmentData(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async checkIfAllSupervisorHaveSaleValueRecalculatedByAccounting(context, payload: { supervisorIdcs: number[] }) {
            const { commit, rootDispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/check-if-all-supervisor-have-sale-value-recalculated-by-accounting/", payload.supervisorIdcs)
                    .then(async (resp: AxiosResponse) => {
                        if (await resp.data.isSuccess == false) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Wymagane przeliczenie frachtów",
                                message: "Wymagane przeliczenie frachtów dla zleceń: " + resp.data.errorMessage,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                            resolve(resp.data.isSuccess);
                            return false;
                        }
                        resolve(resp.data.isSuccess);
                        return true;
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
    }
});

export default supervisorForSettlementModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, supervisorForSettlementModule)