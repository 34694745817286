import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import i18n from "../../assets/i18n";
import { ContractorTypes } from "../../models/emums/contractorTypes";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import LocalStorageApplicationStateDataModel from "../../models/localStorageApplicationStateDataModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import VehicleTypeRestModel from "../../models/rest/vehicle/vehicleTypeRestModel";
import { axiosInstance } from "../../plugins/axios";


export interface CommonModuleStateInterface {
    hangfireUserEmail: string;
    addressFound: any[];
    contractorSearch: ContractorSearchItemModel[];
    cieszynDepartmentIdcs: number[];
    dispoEmailAddressList: string[];
    hasOrHasNotOrAllTypeList: ListItemNumberModel[];
    yesOrNoTypeList: any[];
    localStorageApplicationStateData: LocalStorageApplicationStateDataModel,
    vehicleTypeListForMap: VehicleTypeRestModel[];
    vehicleTypeListForTransportOrder: VehicleTypeRestModel[];
    vehicleTypeListForSupervisor: ListItemStringModel[];
    vehicleIcons: any,
}

const commonModule = defineModule({
    namespaced: true,
    state: {
        hangfireUserEmail: "hangfire@solvsoft.pl",
        addressFound: [] as any[],
        contractorSearch: [] as ContractorSearchItemModel[],
        cieszynDepartmentIdcs: [] as number[],
        dispoEmailAddressList: [] as string[],
        hasOrHasNotOrAllTypeList: [
            { text: i18n.t("common.all").toString(), value: 0 },
            { text: i18n.t("common.yes").toString(), value: 1 },
            { text: i18n.t("common.no").toString(), value: 2 }
        ] as ListItemNumberModel[],
        yesOrNoTypeList: [
            { text: i18n.t("common.yes").toString(), value: true },
            { text: i18n.t("common.no").toString(), value: false }
        ] as any[],
        localStorageApplicationStateData: {
            expandMenu: false,
            showTransporeonTransportOrders: false,
            supervisorPreliminaryTabState: false,
            supervisorLoadingUnloadingTab: true,
            supervisorExcludedCustomersLoadingUnloadingTab: true,
            supervisorCheckpointsTab: true,
            supervisorAdditionalInfoColumn: true,
            supervisorFlagColumns: true,
            supervisorAdditionalButtons: true,
            supervisorListSelectedDepartments: [],
            supervisorArchiveListForSettlementIsWithoutInvoicingColumn: true,
            supervisorArchiveListForSettlementIsServiceCompletedColumn: true,
            supervisorEditingIsPlaceSuggestionsOn: false,
            supervisorShowLoadingUnloadingDetails: false,
        } as LocalStorageApplicationStateDataModel,
        vehicleTypeListForMap: [] as VehicleTypeRestModel[],
        vehicleTypeListForTransportOrder: [] as VehicleTypeRestModel[],
        vehicleTypeListForSupervisor: [] as ListItemStringModel[],
        vehicleIcons: {} as any,
    } as CommonModuleStateInterface,
    mutations: {
        setContractorSearch(state, value: ContractorSearchItemModel[]) {
            state.contractorSearch = value;
        },
        setAddressFound(state, value: any[]) {
            state.addressFound = value ?? [];
        },
        setVehicleTypeListForMap(state, value: VehicleTypeRestModel[]) {
            state.vehicleTypeListForMap = value;
        },
        setVehicleTypeListForTransportOrder(state, value: VehicleTypeRestModel[]) {
            state.vehicleTypeListForTransportOrder = value;
        },
        setVehicleTypeListForSupervisor(state, value: ListItemStringModel[]) {
            state.vehicleTypeListForSupervisor = value;
        },
        setVehicleIcons(state, value: any) {
            const currentVehicleIcons = state.vehicleIcons
            currentVehicleIcons[value.key] = value.value
            state.vehicleIcons = currentVehicleIcons
        },
        setlocalStorageApplicationStateData(state, value: LocalStorageApplicationStateDataModel) {
            state.localStorageApplicationStateData = value;
        },
        setDispoEmailAddressList(state, value: string[]) {
            state.dispoEmailAddressList = value;
        },
        setCieszynDepartmentIdcs(state, value: number[]) {
            state.cieszynDepartmentIdcs = value;
        },
    },
    actions: {
        updateLocalStorageApplicationStateData(context) {
            const { state } = _moduleActionContext(context);
            const json = JSON.stringify(state.localStorageApplicationStateData)
            localStorage.setItem("applicationStateData", json);
        },
        readLocalStorageApplicationStateData(context) {
            const { state, commit } = _moduleActionContext(context);
            try {
                const applicationStateDataJSON = localStorage.getItem("applicationStateData");
                if (applicationStateDataJSON != null) {
                    const applicationStateData = JSON.parse(applicationStateDataJSON);
                    commit.setlocalStorageApplicationStateData(applicationStateData);
                }
            }
            catch (error: any) {
                console.log(error.message);
            }
        },
        async searchContractors(context, payload: { searchText: string, contractorTypes: string[], contractorPropertiesEnabled: string[], source: string, searchOnlyActive?: boolean } = { searchText: "", contractorTypes: [], contractorPropertiesEnabled: [], source: "", searchOnlyActive: false }) {
            const { commit, rootCommit } = _moduleActionContext(context)
            {
                const contractorTypes = payload.contractorTypes;
                if (payload.source == "settlementTransfer") {
                    payload.contractorTypes = [];
                }
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .post("/api/common/contractors", { searchText: payload.searchText, contractorTypes: payload.contractorTypes, contractorPropertiesEnabled: payload.contractorPropertiesEnabled, searchOnlyActive: payload.searchOnlyActive })
                        .then(async (resp: AxiosResponse) => {
                            if (payload.source == "supervisor") {
                                if (payload.contractorTypes.includes(ContractorTypes.Client)) {
                                    await rootCommit.supervisor.setContractorSenderFound(resp.data);
                                }
                                if (payload.contractorTypes.includes(ContractorTypes.Carrier)) {
                                    await rootCommit.supervisor.setContractorCarrierFound(resp.data);
                                }
                                if (payload.contractorTypes.includes(ContractorTypes.Payer)) {
                                    await rootCommit.supervisor.setContractorPayerFound(resp.data);
                                }
                            }
                            if (payload.source == "settlementTransfer") {
                                if (contractorTypes.includes("Sender")) {
                                    await rootCommit.settlementCostDocumentTransferOrder.setContractorSenderFound(resp.data);
                                }
                                if (contractorTypes.includes("Recipient")) {
                                    await rootCommit.settlementCostDocumentTransferOrder.setContractorRecipientFound(resp.data);
                                }
                            }
                            if (payload.source == "supervisorForSettlement") {
                                if (payload.contractorTypes.includes(ContractorTypes.Client)) {
                                    await rootCommit.supervisorForSettlement.setContractorSenderFound(resp.data);
                                }
                                if (payload.contractorTypes.includes(ContractorTypes.Payer)) {
                                    await rootCommit.supervisorForSettlement.setContractorPayerFound(resp.data);
                                }
                                if (payload.contractorTypes.includes(ContractorTypes.Carrier)) {
                                    await rootCommit.supervisorForSettlement.setContractorCarrierFound(resp.data);
                                }
                            }
                            if (payload.source == "supervisorAllOrdersList") {
                                if (payload.contractorTypes.includes(ContractorTypes.Client)) {
                                    await rootCommit.supervisorAllOrders.setContractorSenderFound(resp.data);
                                }
                                if (payload.contractorTypes.includes(ContractorTypes.Carrier)) {
                                    await rootCommit.supervisorAllOrders.setContractorCarrierFound(resp.data);
                                }
                            }
                            else {
                                commit.setContractorSearch(resp.data);
                            }
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        async searchAddresses(context, payload: { searchText: string, additionalSearchText?: string }) {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .post("/api/common/addresses", { searchText: payload.searchText, additionalSearchText: payload.additionalSearchText })
                        .then((resp: AxiosResponse) => {
                            commit.setAddressFound(resp.data.locations);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        async getDistanceBetweenPlaces(context, payload: { placesCoordinates: any[] }): Promise<number> {
            const { commit, rootCommit } = _moduleActionContext(context)
            {
                const model = { text: "placesCoordinates", value: payload.placesCoordinates };
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .post("/api/common/getDistanceBetweenPlaces", payload.placesCoordinates)
                        .then((resp: AxiosResponse) => {
                            resolve(resp.data);
                            return resp.data;
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        loadVehicleTypeList(context, payload: { source: string }) {
            const { commit } = _moduleActionContext(context)
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/common/vehicleTypeList/" + payload.source)
                    .then((resp: AxiosResponse) => {
                        if (payload.source == "map") {
                            commit.setVehicleTypeListForMap(resp.data)
                        }
                        else if (payload.source == "transportOrder") {
                            commit.setVehicleTypeListForTransportOrder(resp.data)
                        }
                        else if (payload.source == "supervisor") {
                            const mapVehicleTypeToListItem = resp.data.map((vehicleType: VehicleTypeRestModel) => ({
                                text: vehicleType.type,
                                value: vehicleType.type
                            }));
                            commit.setVehicleTypeListForSupervisor(mapVehicleTypeToListItem)
                        }
                        resolve(resp.data);
                    })
            })
        },
        async loadVehicleIcon(context, payload: { typeId: number, state: number, key: string }) {
            const { commit } = _moduleActionContext(context)
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/common/vehicleIcon/" + payload.typeId + "/" + payload.state, {
                        responseType: "arraybuffer"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob(
                            [resp.data],
                            { type: resp.headers['content-type'] }
                        )
                        const image = URL.createObjectURL(blob)
                        const iconKey = payload.typeId + '_' + payload.state;
                        commit.setVehicleIcons({ key: iconKey, value: image });
                        resolve(resp.data)
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async loadDispoEmailAddressList(context) {
            const { commit } = _moduleActionContext(context)
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/common/loadDispoEmailAddressList")
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        commit.setDispoEmailAddressList(resp.data)
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async loadCountryTaxRateList(context) {
            const { rootCommit, rootState } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/common/country/" + rootState.register.country.code + "/tax-rate-list")
                    .then((resp: AxiosResponse) => {
                        rootCommit.register.setCountryTaxRateArray(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
    }
});

export default commonModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, commonModule);
