import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { stringMaxLength } from "../../../assets/validation/common";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import DepartmentItemRestModel from "../../../models/rest/register/departmentItemRestModel";
import SupervisorCarrierDocumentOrderRequirementRestModel from "../../../models/rest/supervisor/supervisorCarrierDocumentOrderRequirementRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";
import SupervisorSendToEmailAddressModel from "../../../models/rest/supervisor/supervisorSendToEmailAddressModel";

@Component
export default class SendingEmailDialog extends Vue {
    validation = {
        carrierEmail: [
            stringMaxLength(this.$i18n, this.$t("user.email"), 95),
            (value: string) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || this.$t("common.validationErrorValidEmail");
            }],
        carrierOrderRequirement: [(value: number) => !!value || this.$t("common.validationErrorRequired")],
        emailNote: [stringMaxLength(this.$i18n, this.$t("user.email"), 512)],
    }

    get showDialog(): boolean {
        return this.$store.direct.state.supervisor.showSendingEmailDialog;
    }

    get title(): string {
        return this.$store.direct.state.supervisor.sendingEmailDialogTitle;
    }

    get sendToEmailAddressArray(): SupervisorSendToEmailAddressModel[] {
        return this.$store.direct.state.supervisor.sendingEmailDialogSendToEmailAddressArray;
    }

    set sendToEmailAddressArray(val: SupervisorSendToEmailAddressModel[]) {
        this.$store.direct.commit.supervisor.setSendingEmailDialogSendToEmailAddressArray(val);
    }

    get sendFromEmailAddressList(): string[] {
        return this.$store.direct.state.common.dispoEmailAddressList;
    }

    get sendFromEmailAddress(): string {
        return this.$store.direct.state.supervisor.sendingEmailDialogSendFromEmailAddress;
    }

    set sendFromEmailAddress(val: string) {
        this.$store.direct.commit.supervisor.setSendingEmailDialogSendFromEmailAddress(val);
    }

    get emailNote(): string {
        return this.$store.direct.state.supervisor.sendingEmailDialogEmailNote;
    }

    set emailNote(val: string) {
        this.$store.direct.commit.supervisor.setSendingEmailDialogEmailNote(val);
    }

    get doNotShowCoLoadingIsForbiddenInfo(): boolean {
        return this.$store.direct.state.supervisor.sendingEmailDialogDoNotShowCoLoadingIsForbiddenInfo;
    }

    set doNotShowCoLoadingIsForbiddenInfo(val: boolean) {
        this.$store.direct.commit.supervisor.setSendingEmailDialogDoNotShowCoLoadingIsForbiddenInfo(val);
    }

    get currentUserDefaultDepartmentId(): number {
        return this.$store.direct.state.auth.currentUserDefaultDepartmentId;
    }

    get carrierOrderRequirementList(): SupervisorCarrierDocumentOrderRequirementRestModel[] {
        return this.$store.direct.state.supervisor.supervisorCarrierDocumentOrderRequirementList;
    }

    get carrierOrderRequirementId(): number {
        return this.$store.direct.state.supervisor.sendingEmailDialogCarrierOrderRequirementId;
    }

    set carrierOrderRequirementId(val: number) {
        this.$store.direct.commit.supervisor.setSendingEmailDialogCarrierOrderRequirementId(val);
    }

    get departments(): DepartmentItemRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.departments;
    }

    get cieszynDepartmentIdcs(): number[] {
        const cieszynDepartmentIdcs = this.departments.filter((x: ListItemNumberModel) => {
            if (x.text.includes("Cieszyn")) {
                return x.value;
            }
        }).map((x: ListItemNumberModel) => {
            return x.value;
        })
        return cieszynDepartmentIdcs;
    }

    get supervisor(): SupervisorRestModel {
        return this.$store.direct.state.supervisor.supervisor;
    }

    get selectedContractorCarrier(): ContractorSearchItemModel {
        return this.$store.direct.state.supervisor.selectedContractorCarrier;
    }

    get contractorCarrierEmailList(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.emailAddressList;
    }

    set contractorCarrierEmailList(val: ListItemNumberModel[]) {
        this.$store.direct.commit.contractor.setEmailAddressList(val);
    }

    get contractorCarrierEmailListFormatted(): string[] {
        return this.contractorCarrierEmailList.map((x: ListItemNumberModel) => {
            return x.text;
        })
    }

    @Watch("sendFromEmailAddressList")
    async emailFromAddressListHandles() {
        this.$store.direct.commit.contractor.setContractorAssetsLoaded(false);
        await this.$store.direct.dispatch.contractor.loadContractorAssets();
        await this.$store.direct.dispatch.supervisor.loadSupervisorAssets();

        if (this.cieszynDepartmentIdcs.includes(this.currentUserDefaultDepartmentId)) {
            this.sendFromEmailAddress = this.sendFromEmailAddressList[1];
        }
        else {
            this.sendFromEmailAddress = this.sendFromEmailAddressList[0];
        }

        if (this.departments != undefined) {
            const foundDepartment = this.departments.find((x: DepartmentItemRestModel) => x.value == this.supervisor.locationId)
            this.carrierOrderRequirementId = foundDepartment?.defaulSupervisorCarrierOrderRequirementId ?? 0;
        }
    }

    @Watch("selectedContractorCarrier")
    async selectedContractorCarrierHandles() {
        if (this.selectedContractorCarrier.value != null && this.selectedContractorCarrier.value != undefined) {
            this.$store.direct.dispatch.contractor.loadEmailAddressList({ contractorId: this.selectedContractorCarrier.value });
        }
    }

    addSendToEmailAddress() {
        this.sendToEmailAddressArray.push({ emailAddress: "" });
    }

    removeSendToEmailAddress(index: number) {
        this.sendToEmailAddressArray.splice(index, 1);
    }

    cancelClick() {
        this.$store.direct.dispatch.supervisor.isNotConfirmedSendingEmailDialog();
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.supervisor.isConfirmedSendingEmailDialog();
            this.contractorCarrierEmailList = [] as ListItemNumberModel[];
            this.doNotShowCoLoadingIsForbiddenInfo = false;
        }
    }
}
